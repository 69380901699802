export const homepage = {
  // this is the main section which is header section content for the homepage.
  header: {
    title: "Free Live Chat Software for Website to ",
    description:
      "Offer superb customer support with Live Chat for Website that will delight your customers and fuel sales. Try it FREE with all premium features!",
    buttonText: "Sign Up Free",
  },

  //   this is the section where admin panel slider is moving which is a second section in homepage.
  adminPanelSlider: {
    title: "Live Chatbot",
    subTitle: "Admin Panel for Best Support",
    description:
      "Make your customer interactions smoother with a simple and user-friendly control panel. You can use it to handle all your customer conversations, answer their queries, and see how well your chatbots are working. It's your live chatbot control center for delivering superior customer support service.",
    subDescription:
      "With a smart and always-on chatbot on the website, you can increase your business growth opportunities. Stay connected with your website visitors anytime, anywhere using the Chatmyer App. Integrate online live chat on your website now and start converting website visitors to happy customers",
    buttonText: "Sign Up Free",
  },

  //   our core features section
  ourCoreFeatures: {
    title: "Our Core Features",

    // tabs content
    tabOne: {
      title: "Real-time Live Chat",
      description:
        "Never miss a chat with its real-time live chat functionality connect instantly and engage with your audience seamlessly.",
    },
    tabTwo: {
      title: "Resolve Complaints with Ease",
      description:
        "Put your customers first with Chatmyer's ticket support feature. Easily manage and resolve complaints. ",
    },
    tabThree: {
      title: "Delight Customers with Quick Response",
      description:
        "Amaze your customers with lightning-fast responses that show you truly care.",
    },
    tabFour: {
      title: "Keep Complaint Status Up-to-date",
      description:
        "Stay organized and informed by keeping complaint statuses up-to-date in real-time.",
    },

    qrCodeText: "Scan Me",
  },

  //   chatbots with zero coding section
  chatBotSection: {
    title: "Sign Up in Seconds & Create Chatbots With Zero Coding",

    // tabs content
    tabOne: {
      title: "Quick Sign Up",
      description:
        "Create your account in no time and enjoy crafting chatbots with no coding skills needed.",
    },
    tabTwo: {
      title: "Simple Code Integration",
      description:
        "Integrate code into your website with ease, making chatbot deployment a breeze.",
    },
    tabThree: {
      title: "Start Live Chatting",
      description:
        "Experience live chatting right away – it's as simple as signing up.",
    },
  },

  //   grow your business section

  growYourBusinessSection: {
    title: "Grow Your Business with a Fully Managed Live Chat Platform",

    cardOne: {
      title: "Tailored Chat Experience",
      description:
        "Craft a chat that's uniquely yours! Customize the chat window, agent avatars, company logos, chat buttons, and live chat agent for website welcome messages to match your brand's personality.",
    },
    cardTwo: {
      title: "More and Better Leads",
      description:
        "Chatbots at live chat websites can help you build lasting relationships. When coupled with live chat for websites, they work wonders in improving customer experience.",
    },
    cardThree: {
      title: "Share Images with Ease",
      description:
        "Agents and visitors can share pictures, and screenshots with the click of a button - all within the chat window!.",
    },
    cardFour: {
      title: "Communicate Faster Than Ever",
      description:
        "Thanks to Chatmyer’s state-of-the-art technology, your website live chat's loading speed remains unaffected, ensuring quick and responsive interactions.",
    },
    cardFive: {
      title: "Always-On Customer Support",
      description:
        "Experience the unbeatable advantage of 24/7 customer support through Chatmyer live chat for website. It enables you to assist customers, day or night, without any delays.",
    },
    cardSix: {
      title: "Reduce Operational Costs",
      description:
        "Say goodbye to the need for a large customer support team for your business. Live chat website chatbots are your cost-efficient solution for streamlining operations.",
    },
  },

  //Awesome Features Section
  awesomeFeaturesSection: {
    title: "Awesome Features to Wow Your Web Visitors",

    cardOne: {
      title: "Mobile App to Chat Anywhere",
      description:
        "Stay connected on the go – never miss a conversation again. Chatmyer Mobile App, the best live chat application for websites, keeps you in the loop wherever you are.",
    },
    cardTwo: {
      title: "Custom Branding on Popup",
      description:
        "Make your live chat match your website! Select the theme color that goes with your brand to make chatting feel like a natural part of your website.",
    },
    cardThree: {
      title: "Instant Local Notifications",
      description:
        "Get quick notifications on your mobile and desktop app from Chatmyer. It helps you respond fast and help customers without any delays.",
    },
  },
};
