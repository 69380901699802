export const aboutpage = {
  headerTitle: "Meet the all-in-one customer communications platform",

  //   this is chatmyer section
  aboutChatmyer: {
    title: "This is Chatmyer",
    description:
      "A company that brings together talented people from all over the world to solve tricky customer problems. We believe great relationships start with excellent customer support. That's why our dedicated team brings the #1 Live Chat Software, easy to use and geared to help your business grow.",
    description_two:
      "Our teams spend years perfecting their skills to deliver exceptional software that customers love. We're committed to make business less complicated and more straightforward for both companies and customers to connect. This way, you can work better, have sharper support teams, and keep your customers happier.",
  },

  //   give your business chance section

  business: {
    title: "Give your business a chance to soar to new heights",
    sub_title: "Let's get you started.",
    btnText: "Try For Free",
  },

  missionVision: {
    mission: {
      title: "Our Mission",
      description:
        "We’re on a mission to bring companies and customers closer. Chatmyer is all about making affordable, functional, and super helpful live chat for websites. We're always pushing the limits in our thinking, design, and coding to make our software better every day.",
    },

    vision: {
      title: "Our Vision",
      description:
        "Our goal is to make exceptional customer service through live chat for websites accessible and standard for every business. We aim to let everyone communicate with website visitors, help them buy, and resolve queries without any unnecessary costs—truly free. ",
    },
  },

  //   signup in seconds section

  signupSection: {
    title: "Sign Up in Seconds & Create Chatbots With Zero Coding",
  },
};
