import React from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import JoinNow from "../components/JoinNow";
import arrow from "../assets/icons/arrow.svg";

const TermsConditions = () => {
  return (
    <>
      <div className="terms-page translate-y-24 pt-10  p-10 sm:w-10/12 mx-auto border-2 border-primary-purple rounded-2xl">
        {/* header section */}
        <div className="intro ">
          <div className="text pt-5 ">
            <h1 className="text-primary-dark text-center font-bold text-3xl sm:text-5xl">
              Privacy Policy
            </h1>
            <p className="pt-10 font-medium">
              At Chatmyer, we value your privacy and are committed to protecting
              it through this policy. This privacy statement was created to
              assist you in understanding what information we gather and how we
              use, process, and share that information.
            </p>
            <br />
            <p className="pt-5  font-medium">
              Our privacy statement applies to all of our goods and services
              (collectively referred to as "Services"), including
              https://chatmyer.com/ (and their subdomains), as well as linked
              mobile apps owned and operated by Chatmyer. We ensure you to not
              use or share your information with anyone except as described in
              this Privacy Policy.
            </p>
          </div>
        </div>
        <div className="intro">
          <div className="text pt-14">
            <h1 className="text-primary-dark font-bold text-xl   md:text-[28px]">
              What Information Chatmyer Collects
            </h1>

            <p className="py-4  font-medium">
              We only collect information that is absolutely necessary. Part of
              that information is information you actively provide to us when
              you sign up for an account, register for an event, or contact
              customer service. We save your name and contact information, but
              not your credit card information.
            </p>
            <p className="py-4  font-medium">
              We may automatically log some basic information when you visit one
              of our websites or use our software, such as how you arrived at
              the site, where you traveled within it, and what services and
              settings you use. This data is used to improve our websites and
              services, as well as to drive new product development.
            </p>
            <p className="py-4  font-medium">
              We at Chatmyer may get information in an indirect manner. If you
              inquire about our services without signing up, or if you sign in
              to one of our products using an authentication services provider
              such as LinkedIn or Google, they will give us with your contact
              information. We will use such information to fulfill your request.
            </p>
          </div>
        </div>
        <div className="intro">
          <div className="text pt-5 ">
            <h1 className="text-primary-dark font-bold text-xl   md:text-[28px]">
              What We Do With Your Personal Information
            </h1>
            <p className="pt-2  font-medium">
              Your information is used in the following ways by us:
            </p>
            <ul className="pt-4 space-y-2 list-inside">
              <li className="flex gap-3  font-medium">
                <img src={arrow} alt="" /> To provide, maintain, and upgrade the
                Services made available to you.
              </li>
              <li className="flex gap-3  font-medium">
                <img src={arrow} alt="" /> To offer you client service.
              </li>
              <li className="flex gap-3  font-medium">
                <img src={arrow} alt="" /> To handle your payments for our
                Services.
              </li>
              <li className="flex gap-3  font-medium">
                <img src={arrow} alt="" /> Controlling unauthorized use or
                misuse of the Services, as well as preventing behaviors that may
                violate our rules or are prohibited.
              </li>
              <li className="flex gap-3  font-medium">
                <img src={arrow} alt="" /> To collect demographic information
                and monitor use or traffic trends (including monitoring users'
                movements around the Services).
              </li>
              <li className="flex gap-3  font-medium">
                <img src={arrow} alt="" /> Direct communication with you, such
                as sending you emails, newsletters, promotions and special
                offers, or information about new products and services.
              </li>
              <li className="flex gap-3  font-medium">
                <img src={arrow} alt="" /> To deliver you a tailored user
                experience.
              </li>
            </ul>
            <p className="py-4  font-medium">
              We keep the personal information you supply us for as long as we
              think it could be relevant in contacting you about the Services,
              and then we securely destroy it. If you request it, we will erase
              this information from the servers sooner.
            </p>
          </div>
        </div>
        <div className="intro">
          <div className="text pt-5">
            <h1 className="text-primary-dark font-bold text-xl   md:text-[28px]">
              Your Data Protection Rights & Choices
            </h1>
            <p className="pt-2  font-medium">
              We want to make sure you completely understand all of your data
              protection rights. Every user has the following rights (especially
              residents of the European Economic Area):
            </p>
            <h3 className=" font-bold text-base py-4 ">Right of access:</h3>
            <p className=" font-medium">
              You have the right to access (and, if necessary, receive a copy
              of) the categories of personal information that we keep about you,
              including the source, purpose, and length of processing, as well
              as the individuals with whom the information is shared.
            </p>
            <h3 className=" font-bold text-base py-4">
              Right to rectification:
            </h3>
            <p className=" font-medium">
              You have the right to update or correct any inaccurate information
              we have on you. You can ask us to include extra information about
              you in our database based on the reason for which we utilize your
              information.
            </p>
            <h3 className=" font-bold text-base py-4">Right to erasure:</h3>
            <p className=" font-medium">
              In some cases, such as when your personal information is no longer
              required for the purpose for which it was originally obtained, you
              have the right to request that we remove it.
            </p>
            <h3 className=" font-bold text-base py-4">
              Right to data restriction:
            </h3>
            <p className=" font-medium">
              You may also have the right to request that your information be
              restricted from being used in certain instances, such as when you
              object to our use of your data but we need to check if we have
              overriding legal grounds to use it.
            </p>
            <h3 className=" font-bold text-base py-4">Data portability:</h3>
            <p className=" font-medium">
              You have the right to transfer your information to a third party
              in a structured, frequently used, and machine-readable format if
              the information is processed with your permission or by automated
              methods.
            </p>
            <h3 className=" font-bold text-base py-4">Right to object:</h3>
            <p className=" font-medium">
              In some cases, such as the use of your personal information for
              direct marketing, you have the right to object to the use of your
              information.
            </p>
            <h3 className=" font-bold text-base py-4">Right to Complain:</h3>
            <p className=" font-medium">
              If you have a complaint about how we collect, use, or distribute
              personal information, you have the right to file it with the
              appropriate supervisory body. This right may not be available to
              you in the case if your nation lacks a data protection supervisory
              authority.
            </p>
          </div>
        </div>
        <div className="intro">
          <div className="text pt-5">
            <h1 className="text-primary-dark py-5 font-bold text-xl   md:text-[28px]">
              Information Retention
            </h1>
            <p className="pt-2 font-medium">
              We save your personal information for as long as it is needed for
              the reasons outlined in this Privacy Statement. We may preserve
              your information for extended periods of time if authorized or
              needed by law, such as to maintain suppression lists, prevent
              misuse, if required in connection with a legal claim or process,
              to enforce our agreements, for tax, accounting, or other legal
              responsibilities.
            </p>
            <p className="pt-2 font-medium">
              We will erase or anonymize your information from our active
              databases once we no longer have a genuine need to handle it. We
              will also securely store the information on backup discs and
              separate it from further processing until deletion is possible.
            </p>
          </div>
          <div className="text pt-5">
            <h1 className="text-primary-dark py-5 font-bold text-xl   md:text-[28px]">
              How Secure is Your Personal Information?
            </h1>
            <p className="pt-2 font-medium">
              Chatmyer takes data security extremely seriously. That is why we
              take sufficient and reasonable technical and organizational
              measures to protect Personal Information from loss, misuse,
              unauthorized access, disclosure, alteration, and destruction while
              taking into account the risks associated with the processing and
              the nature of the Personal Information. If you still have any
              concerns about the security of your data, we invite you to review
              our Terms & Conditions or Contact Us.
            </p>
          </div>
          <div className="text pt-5">
            <h1 className="text-primary-dark py-5 font-bold text-xl   md:text-[28px]">
              Disclosure of Information to Third Parties
            </h1>
            <p className="pt-2 font-medium">
              We may disclose your personal information to third parties who
              provide services on our behalf, such as payment processors,
              hosting providers, and customer support providers. We may also
              disclose your information if required by law or if necessary to
              protect our legal rights or the rights of our users.
            </p>
          </div>
          <div className="text pt-5">
            <h1 className="text-primary-dark py-5 font-bold text-xl   md:text-[28px]">
              Changes to this Chatmyer Privacy Policy
            </h1>
            <p className="pt-2 font-medium">
              We may amend our Privacy Policy from time to time to reflect new
              technology, industry practices, legal requirements, or other
              factors. We will always present the most recent version on our
              digital channels. As a result, we recommend that you read the
              Privacy Policy on a frequent basis to stay informed of changes
              that may affect you.
            </p>
          </div>
          <div className="text py-5">
            <h1 className="text-primary-dark py-5 font-bold text-xl   md:text-[28px]">
              How to Contact Us
            </h1>
            <p className="pt-2 font-medium">
              If you need any further information or have any concerns regarding
              our Privacy Policy, please email us at support@chatmyer.com.
            </p>
          </div>
        </div>
      </div>
      <div className="pt-28 pb-10 translate-y-16">
        <JoinNow />
      </div>
      <Footer />
    </>
  );
};

export default TermsConditions;
