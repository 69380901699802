import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import mobile1 from "../assets/images/mobile1.webp";
import mobile2 from "../assets/images/mobile2.webp";
import scanme from "../assets/images/scanme.webp";
import Footer from "../components/Footer";

const Mobile = () => {
  const [currentOs, setCurrentOs] = useState("");

  function detectDeviceType() {
    // Check the user agent
    const userAgent = navigator.userAgent.toLowerCase();

    if (
      /android|webos|iphone|ipod|blackberry|iemobile|opera mini/i.test(
        userAgent
      )
    ) {
      // This is a mobile device

      if (
        /android/i.test(userAgent) &&
        window.innerWidth > 600 &&
        window.innerHeight > 1024
      ) {
        return "Tablet";
      }
      if (/tablet/i.test(userAgent)) {
        return "Tablet";
      }
      return "Mobile";
    } else if (
      /tablet|ipad/i.test(userAgent) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
    ) {
      // This is a tablet device
      return "Tablet";
    } else {
      return "Desktop";
    }
  }

  useEffect(() => {
    const val = detectDeviceType();
    if (val !== "Mobile") {
      window.location.href = "https://admin.chatmyer.com/login";
    }
  }, []);

  //   custom logic
  const windowWidth = useRef(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      windowWidth.current = window.innerWidth;
      if (windowWidth.current > 600) {
        window.location.href = "https://admin.chatmyer.com/login";
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const val = detectDeviceType();

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.includes("android")) {
      setCurrentOs("android");
    } else if (userAgent.includes("iphone")) {
      setCurrentOs("iphone");
    } else if (userAgent.includes("ipod")) {
      setCurrentOs("iphone");
    } else {
      // Handle other cases or set a default value if the user agent doesn't match any of the above
    }
  }, []);

  const sendToStore = () => {
    if (currentOs === "android") {
      window.location.href = process.env.REACT_APP_ANDROID_STORE_URL;
    }

    if (currentOs === "iphone") {
      window.location.href = process.env.REACT_APP_IOS_STORE_URL;
    }
  };

  return (
    <>
      {val == "Mobile" && (
        <div className="pt-16">
          <div className="header-image  p-3 relative">
            <img
              className=" hover:scale-100 w-full"
              src={mobile1}
              loading="lazy"
            />
            <Link onClick={sendToStore}>
              <button className=" absolute bottom-8 left-8 md:left-20 px-6 py-3 font-bold tracking-wider mt-10 bg-white rounded-full text-primary-purple ">
                DOWNLOAD
              </button>
            </Link>
          </div>

          <div className="text-center pt-6">
            <h1 className=" text-3xl font-extrabold">
              OR Scan QR Code To Download
            </h1>
            <img className="w-1/2 m-auto mt-8" src={scanme} loading="lazy" />
          </div>

          <div className=" p-3 mt-8 ">
            <img
              className=" hover:scale-100 w-full"
              src={mobile2}
              loading="lazy"
            />
          </div>

          <Footer />
        </div>
      )}
    </>
  );
};

export default Mobile;
