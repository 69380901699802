import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.svg";
const Footer = () => {
  return (
    <div className="footer pt-20 pb-5 px-3 sm:px-0">
      <div className="grid grid-cols-1">
        <div className="main-footer  flex items-center flex-wrap justify-between">
          <div className="left flex items-center gap-1 sm:gap-5 text-primary-dark">
            <Link to="/">
              <img
                className="w-20 md:w-[150px] md:my-0 my-3 "
                src={logo}
                alt="logo"
              />
            </Link>
            <p className="text-sm">&copy; 2022-2023</p>
          </div>
          <div className=" text-primary-dark flex gap-3 sm:gap-10 flex-wrap">
            <Link
              to="/terms-conditions"
              className="text-xs sm:text-base hover:underline hover:underline-offset-8 decoration-primary-purple decoration-2"
            >
              Terms & Conditions
            </Link>
            <Link
              to="/privacy-policy"
              className="text-xs sm:text-base hover:underline hover:underline-offset-8 decoration-primary-purple decoration-2"
            >
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
