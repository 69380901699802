import React from "react";
import { useState } from "react";
import open from "../assets/icons/open.svg";
import close from "../assets/icons/close.svg";
import { faqspage } from "../content/faqs";

const Faq = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [openAccordion, setOpenAccordion] = useState(null);

  const toggleAccordion = (index) => {
    setOpenAccordion((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="pt-16">
      <h1 className="text-xl md:text-4xl font-semibold text-primary-dark text-center pb-16">
        Chatmyer FAQs
      </h1>

      <div className="faqs pb-20">
        <div className="w-full mx-auto">
          {faqspage.faq.map((accordion, index) => (
            <div key={index} className="border-b border-gray-200 rounded mb-4">
              <button
                type="button"
                className="flex gap-2 items-center w-full p-4 focus:outline-none"
                onClick={() => toggleAccordion(index)}
              >
                {openAccordion === index ? (
                  <img src={close} alt="" />
                ) : (
                  <img src={open} alt="" />
                )}
                <span className="font-medium text-left">{accordion.title}</span>
              </button>

              <div
                className={`faq-content ${
                  openAccordion === index ? "faq-active" : "faqq"
                } `}
              >
                <p className="text-gray-700 p-5">{accordion.content}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Faq;
