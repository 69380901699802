import React, { useState, useRef } from "react";
import axios from "axios";
import Footer from "../components/Footer";
import loginDots from "../assets/images/login-dots.svg";
import loginDotOne from "../assets/images/login-dotone.svg";
import loginDotTwo from "../assets/images/login-dot-two.svg";
import copy from "../assets/icons/copy.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import SyntaxHighlighter from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
export const Register = () => {
  const fileRef = useRef();
  const [previewImage, setPreviewImage] = useState();
  const [previewPassword, setPreviewPassword] = useState(false);
  const [previewPasswordTwo, setPreviewPasswordTwo] = useState(false);
  const [file, setFile] = useState();
  const [successPopup, setSuccessPopup] = useState(false);
  const [color, setColor] = useState("");
  const [errors, setErrors] = useState({
    name: true,
    email: true,
    password: true,
    password_confirmation: true,
    allErrors: false,
  });
  const [errorMessages, setErrorMessages] = useState({
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
  });

  const [cdnLinks, setCdnLinks] = useState({
    cssCdn:
      '<link rel="stylesheet" href="https://cdn.chatmyer.com/chat_popup.css">',
    jsCdn: "",
  });
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    logo: "",
    theme_color: "#7055da",
  });

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setErrors({ ...errors, allErrors: false });
    let regName = /^[a-zA-Z\s]+$/;

    // regex for checking if email is valid or not
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    setFormValues({ ...formValues, [e.target.name]: e.target.value });
    // name validation
    if (name === "name") {
      if (!value || value.trim() === "") {
        setErrorMessages({ ...errorMessages, name: "Name is Required" });
      } else if (value.length < 6) {
        setErrorMessages({
          name: "Name must be longer than five characters",
        });
        setErrors({ ...errors, name: true });
        // ======
      } else if (!regName.test(value)) {
        setErrorMessages({
          name: "Only letters (a-z, A-Z) are allowed",
        });
        setErrors({ ...errors, name: true });
        // ======
      } else {
        setErrorMessages({
          ...errorMessages,
        });
        setErrors({ ...errors, name: false });
      }
    }

    // email validation
    if (name === "email") {
      if (!value || value.trim() === "") {
        setErrorMessages({ ...errorMessages, email: "Email is Required" });
        setErrors({ ...errors, email: true });
      } else if (!regEmail.test(value)) {
        setErrorMessages({
          ...errorMessages,
          email: "Please enter a valid email",
        });
        setErrors({ ...errors, email: true });
      } else {
        setErrorMessages({
          ...errorMessages,
        });
        setErrors({ ...errors, email: false });
      }
    }
    // password validations
    if (name === "password") {
      if (!value || value.trim() === "") {
        setErrorMessages({ password: "Password can't be empty" });
        setErrors({ ...errors, password: true });
      } else if (value.length < 6) {
        setErrorMessages({
          password: "Password must be at least  six characters",
        });
        setErrors({ ...errors, password: true });
      } else {
        setErrorMessages({
          ...errorMessages,
        });
        setErrors({ ...errors, password: false });
      }
    }
    // confirm password validations
    if (name === "password_confirmation") {
      if (!value || value.trim() === "") {
        setErrorMessages({
          ...errorMessages,
          password_confirmation: "Confirm Password can't be empty",
        });
        setErrors({ ...errors, password_confirmation: true });
      } else if (formValues.password !== value) {
        setErrorMessages({
          ...errorMessages,
          password_confirmation: "Password and Confirm password must match",
        });
        setErrors({ ...errors, password_confirmation: true });
      } else {
        setErrorMessages({
          ...errorMessages,
        });
        setErrors({ ...errors, password_confirmation: false });
      }
    }

    if (name === "theme_color") {
      setFormValues({ ...formValues, theme_color: value });

      setColor(value.replace(/#/g, ""));
    }
  };

  const handleLogo = (e) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setPreviewImage(event.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
      setFile(e.target.files[0]);
    }
  };

  const submitForm = async () => {
    let regName = /^[a-zA-Z\s]+$/;
    if (!regName.test(formValues.name)) {
      toast.info("Only letters (a-z, A-Z) are allowed in name");
      return;
    }

    let str = formValues.theme_color;
    let colorWithoutHash = str.replace(/#/g, "");

    if (errors.email || errors.password || errors.password_confirmation) {
      setErrors({ ...errors, allErrors: true });
      return;
    } else {
      setErrors({ ...errors, allErrors: false });
    }

    const formData = new FormData();
    if (file) {
      formData.append("logo", file);
    }
    formData.append("name", formValues.name);
    formData.append("email", formValues.email);
    formData.append("password", formValues.password);
    formData.append("password_confirmation", formValues.password_confirmation);
    formData.append("theme_color", colorWithoutHash);

    const response = await axios.post(
      "https://admin.chatmyer.com/api/register",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );

    if (response.data.status === false) {
      toast.info(
        response.data
          ? response.data.message
          : "This Email has already been taken"
      );
    }
    if (response.data.status === 200) {
      setCdnLinks({ ...cdnLinks, jsCdn: response.data.data.admin_key });
      setSuccessPopup(true);
      setFormValues({
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
      });
    }
  };

  const copyUrlToClipBoard = (e, val) => {
    e.preventDefault();

    toast.success("Copied To Clipboard!");
    const el = document.createElement("input");
    el.value = val.replace(/\s/g, " ");
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };

  const copyUrlToClipBoardJs = (e, val) => {
    e.preventDefault();

    const scriptElement = document.createElement("script");
    scriptElement.src = "https://cdn.chatmyer.com/chat_popup.js";
    scriptElement.setAttribute("key", cdnLinks.jsCdn);
    scriptElement.setAttribute("theme_color", color);
    scriptElement.setAttribute("defer", "true");

    const el = document.createElement("textarea");
    el.value = scriptElement.outerHTML;
    document.body.appendChild(el);

    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    toast.success("Copied To Clipboard!");
  };

  return (
    <>
      <ToastContainer />
      <div className="register  pt-[150px] ">
        <div className="h-screen">
          <div className="sign-in  w-11/12 sm:w-9/12 lg:w-7/12 xl:w-6/12 flex justify-center mx-auto relative duration-500">
            <div className="bg-white w-full md:w-11/12 xl:w-4/6 drop-shadow-xl text-center px-5 py-6  border-[5px] border-primary-purple-dark rounded-xl">
              <h1 className="font-bold text-4xl text-primary-dark">
                Sign Up Account
              </h1>
              <p className="text-base font-normal pt-3">
                Enter your Email and Password
              </p>
              <div className="form  pt-5">
                <form className="">
                  <input
                    className={`${
                      errors.name && errors.allErrors
                        ? "border-b-red-500 border-b"
                        : "focus:border-b-primary-purple"
                    }block w-full py-4 border-b-2 pl-3  outline-none duration-200`}
                    type="text"
                    name="name"
                    placeholder={`${
                      formValues.name === "" && errors.allErrors
                        ? "Name is Required"
                        : "Name"
                    }`}
                    onChange={handleOnChange}
                    value={formValues.name}
                  />
                  <div className="my-2 ">
                    {errors.name && (
                      <p className="text-left text-red-500 text-xs font-light">
                        {errorMessages.name}
                      </p>
                    )}
                  </div>

                  {/* email field */}
                  <input
                    className={`${
                      errors.email && errors.allErrors
                        ? "border-b-red-500 border-b"
                        : "focus:border-b-primary-purple"
                    }block w-full py-4 border-b-2 pl-3  outline-none duration-200`}
                    type="email"
                    name="email"
                    placeholder={`${
                      formValues.email === "" && errors.allErrors
                        ? "Email is Required"
                        : "Enter your Email"
                    }`}
                    onChange={handleOnChange}
                    value={formValues.email}
                  />
                  <div className="my-2 ">
                    {errors.email && (
                      <p className="text-left text-red-500 text-xs font-light">
                        {errorMessages.email}
                      </p>
                    )}
                  </div>

                  {/* email field and validation ends here */}

                  {/* password field */}
                  <div className="password relative">
                    <input
                      className={`${
                        errors.password && errors.allErrors
                          ? "border-b-red-500 border-b"
                          : "focus:border-b-primary-purple"
                      }block w-full py-4 border-b-2 pl-3  outline-none duration-200`}
                      type={previewPassword ? "text" : "password"}
                      name="password"
                      placeholder={`${
                        formValues.password === "" && errors.allErrors
                          ? "Password is Required"
                          : "Password"
                      }`}
                      onChange={handleOnChange}
                      value={formValues.password}
                    />

                    <svg
                      onClick={() => {
                        setPreviewPassword(!previewPassword);
                      }}
                      className="absolute top-5 right-6 cursor-pointer bi bi-eye"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />{" "}
                      <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                    </svg>

                    <div className="my-2 ">
                      {errors.password && (
                        <p className="text-left text-red-500 text-xs font-light">
                          {errorMessages.password}
                        </p>
                      )}
                    </div>
                  </div>

                  {/* password field and validation ends here */}

                  {/* confirm password */}
                  <div className="relative">
                    <input
                      className={`  ${
                        errors.password_confirmation && errors.allErrors
                          ? "border-b-red-500 border-b"
                          : "focus:border-b-primary-purple"
                      }block w-full py-4 border-b-2 pl-3  outline-none duration-200`}
                      type={previewPasswordTwo ? "text" : "password"}
                      name="password_confirmation"
                      placeholder={`${
                        formValues.password_confirmation === "" &&
                        errors.allErrors
                          ? "Confirm Password is Required"
                          : "Confirm Password"
                      }`}
                      onChange={handleOnChange}
                      value={formValues.password_confirmation}
                    />

                    <div className="my-2 ">
                      {errors.password_confirmation && (
                        <p className="text-left text-red-500 text-xs font-light">
                          {errorMessages.password_confirmation}
                        </p>
                      )}
                    </div>

                    <svg
                      onClick={() => {
                        setPreviewPasswordTwo(!previewPasswordTwo);
                      }}
                      className="absolute top-5 right-6 cursor-pointer bi bi-eye"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />{" "}
                      <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                    </svg>
                  </div>
                  {/* confirm_password field and validation ends here */}

                  {/* select Theme Color */}

                  <div className="flex items-center gap-5 pl-3 pt-3">
                    <label
                      className="text-gray-500 text-sm cursor-pointer"
                      htmlFor="color"
                    >
                      Select Theme Color
                    </label>
                    <input
                      className={`w-1/2 cursor-pointer`}
                      type="color"
                      name="theme_color"
                      placeholder="select theme color"
                      id="color"
                      onChange={handleOnChange}
                      value={formValues.theme_color}
                    />
                  </div>

                  {/* logo image uploading */}

                  <div className="logo-uploading py-4">
                    <div className="w-full py-5">
                      {previewImage && (
                        <img
                          className="w-20 h-20 rounded-full mx-auto object-cover"
                          src={previewImage}
                          loading="lazy"
                        />
                      )}
                    </div>
                    <label
                      htmlFor="file-input"
                      className="block w-full py-2 cursor-pointer border border-primary-purple shadow-sm rounded-md text-sm focus:z-10 
                    file:bg-transparent file:border-0
                  file:bg-gray-100 file:mr-4
                    file:py-3 file:px-4"
                    >
                      Select Profile Image
                    </label>
                    <input
                      type="file"
                      id="file-input"
                      name="logo"
                      ref={fileRef}
                      accept="image/*"
                      onChange={handleLogo}
                      hidden
                    />
                  </div>

                  <div className="btn py-3">
                    <button
                      onClick={submitForm}
                      type="button"
                      className="py-4 px-4 w-1/2 bg-primary-purple hover:bg-primary-purple-dark text-white  transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none rounded-full "
                    >
                      Sign Up
                    </button>
                  </div>
                </form>
              </div>
            </div>

            {/* endd of login */}
            <div className="svgs-images ">
              <img
                className="top-0 right-0 -z-10 absolute wiggle"
                src={loginDots}
                alt="decorator"
                loading="lazy"
              />
              <img
                className="-bottom-5 sm:-bottom-10 left-0 sm:left-20 md:left-40 lg:left-0 lg:ml-20 -z-10 absolute wiggle"
                src={loginDotOne}
                alt="decorator"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        {/* footer */}
        <Footer />
      </div>

      {/* popup modal */}
      {successPopup && (
        <div
          className="fixed z-10 overflow-y-auto top-0 w-full left-0 "
          id="modal"
        >
          <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-900 opacity-75" />
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
              &#8203;
            </span>
            <div
              className="border-primary-purple border-4 inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <h2 className="text-3xl text-center text-green-500">
                  CDN's Generated Successfully
                </h2>
                <div className="py-5">
                  <p className="py-2 text-primary-purple">
                    Kindly add this link tag before the closing of your head
                    tag.
                  </p>
                  <img
                    className="w-4 copy-icon absolute  translate-y-3 right-10 z-10"
                    src={copy}
                    alt="copy-code-icon"
                    loading="lazy"
                    onClick={(e) => copyUrlToClipBoard(e, cdnLinks.cssCdn)}
                  />
                  <SyntaxHighlighter
                    className="relative"
                    language="javascript"
                    style={vscDarkPlus}
                    wrapLongLines={true}
                  >
                    {cdnLinks.cssCdn}
                  </SyntaxHighlighter>
                </div>
                <div className="py-5">
                  <p className="py-2 text-primary-purple">
                    {`Kindly add this script tag right before the closing 
                     </body>
                    tag.`}
                  </p>
                  <img
                    className="w-4 copy-icon absolute  translate-y-3 right-10 z-10"
                    src={copy}
                    alt="copy-code-icon"
                    loading="lazy"
                    onClick={(e) => copyUrlToClipBoardJs(e, cdnLinks.jsCdn)}
                    // onClick={(e) => copyUrlToClipBoardJs(e, cdnLinks.jsCdn)}
                  />

                  <SyntaxHighlighter
                    language="javascript"
                    className="relative"
                    style={vscDarkPlus}
                    wrapLongLines={true}
                  >
                    {`<script src="https://cdn.chatmyer.com/chat_popup.js" key="${cdnLinks.jsCdn}" theme_color="${color}" defer></script>`}
                  </SyntaxHighlighter>
                </div>
              </div>
              <div className="bg-gray-200 px-4 py-3 text-right space-x-4">
                <Link to="https://admin.chatmyer.com/login">
                  <button
                    type="button"
                    className="py-2 px-4 bg-primary-purple text-white rounded hover:bg-purple-700 mr-2"
                  >
                    Login
                  </button>
                </Link>
                <button
                  onClick={() => {
                    setSuccessPopup(false);
                    setFormValues({
                      name: "",
                      email: "",
                      password: "",
                      password_confirmation: "",
                      logo: "",
                    });
                    setPreviewImage("");
                    fileRef.current.value = "";
                  }}
                  type="button"
                  className="py-2 px-4 bg-primary-purple text-white rounded hover:bg-purple-700 mr-2"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
