import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import emailBg from "../assets/images/email-bg.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import signup from "../assets/images/signup.png";

const JoinNow = () => {
  // const [successPopup, setSuccessPopup] = useState(false);

  // const [formValues, setFormValues] = useState({
  //   email: "",
  // });
  // const [errors, setErrors] = useState({
  //   email: true,
  // });
  // const [errorMessages, setErrorMessages] = useState({
  //   email: "",
  // });

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormValues({ email: e.target.value });
  //   let regEmail =
  //     /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  //   if (name === "email") {
  //     if (!value || value.trim() === "") {
  //       setErrorMessages({ email: "Email is Required" });
  //       setErrors({ ...errors, email: true });
  //     } else if (!regEmail.test(value)) {
  //       setErrorMessages({
  //         email: "Please enter a valid email",
  //       });
  //       setErrors({ ...errors, email: true });
  //     } else {
  //       setErrorMessages({
  //         ...errorMessages,
  //       });
  //       setErrors({ ...errors, email: false });
  //     }
  //   }
  // };

  // const subscribeNewsletter = async (e) => {
  //   e.preventDefault();
  //   if (formValues.email == "") {
  //     toast.error("Email is Required");
  //     return;
  //   }
  //   if (errors.email) {
  //     toast.error(errorMessages.email);
  //     return;
  //   }
  //   const response = await axios.post(
  //     "https://admin.chatmyer.com/api/leads",
  //     formValues,
  //     {
  //       headers: {
  //         "Content-Type": "application/json",
  //         "Access-Control-Allow-Origin": "*",
  //       },
  //     }
  //   );

  //   if (response.data.status === false) {
  //     toast.info(response.data.message);
  //   }

  //   if (response.data.status == 200) {
  //     setSuccessPopup(true);
  //   }

  //   setFormValues({ email: "" });
  // };

  // useEffect(() => {
  //   setTimeout(() => {
  //     setSuccessPopup(false);
  //   }, 3000);
  // }, [successPopup]);
  return (
    <>
      <div
        className="grid grid-cols-1 items-center gap-8 px-10 py-8 lg:py-0 lg:grid-cols-2 justify-between bg-primary-purple"
        style={{
          backgroundImage: `url(${emailBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          borderRadius: "20px",
        }}
      >
        <div className="text-white col">
          <h2>JOIN NOW</h2>
          <h1 className="pt-5 text-lg font-bold leading-tight md:text-3xl lg:text-5xl">
            Live Chat Builder to Capture 10x More Leads
          </h1>
          <p className="pt-4 text-base">
            Automate your customer support and engage with your customers 24/7
            with Chatmyer!
          </p>
          <Link to="/register">
            <button className="flex items-center gap-3 mt-5 transition-all duration-200 hover:gap-5">
              Create Account
              <svg
                width="34"
                height="17"
                viewBox="0 0 34 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 8.87793H33"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M26 1.87793L33 8.87793L26 15.8779"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </Link>
        </div>
        <div className="col">
          {/* <div className="pt-5 ">
            <div className="relative w-full">
              <div className="">
                <input
                  type="email"
                  className="block p-2.5 pl-5 ml-auto w-full lg:w-3/4 focus:outline-none focus:outline-primary-border text-primary-dark rounded-full"
                  placeholder="Your Email Address"
                  name="email"
                  onChange={handleChange}
                  value={formValues.email}
                />
                {errors.email && (
                  <p className="pt-3 pl-5 md:w-1/2 text-xs mx-auto text-red-300">
                    {errorMessages.email}
                  </p>
                )}
              </div>

              <button
                onClick={subscribeNewsletter}
                type="submit"
                className=" hidden sm:block absolute top-0 right-0 px-6 py-3 text-sm text-white rounded-full md:px-10 hover:bg-zinc-900 bg-primary-dark border-primary-dark"
              >
                Newsletter
              </button>
            </div>
            <div className="flex sm:hidden ">
              <button
                onClick={subscribeNewsletter}
                type="submit"
                className="ml-auto px-6 py-3 text-sm text-white rounded-full md:px-10 hover:bg-zinc-900 bg-primary-dark border-primary-dark"
              >
                Newsletter
              </button>
            </div>
          </div> */}

          <div className="p-5 flex justify-center md:justify-end">
            <img className="w-[350px] justify-end" src={signup} alt="" />
          </div>
        </div>
      </div>

      {/* popup modal */}
      {/* {successPopup && (
        <div
          className="fixed top-0 left-0 z-10 w-full overflow-y-auto "
          id="modal"
        >
          <div className="flex items-center justify-center px-4 pt-4 pb-20 text-center min-height-100vh sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-900 opacity-75" />
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
              &#8203;
            </span>
            <div
              className="inline-block overflow-hidden text-left transition-all transform bg-white border-4 rounded-lg shadow-xl border-primary-purple align-center sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                <h2 className="text-3xl text-center text-green-500">Success</h2>
                <div className="py-5 text-center">
                  <h4>You have Successfully subscribed to our Newsletter.</h4>
                </div>
              </div>
              <div className="px-4 py-3 text-right bg-gray-200">
                <button
                  onClick={() => {
                    setSuccessPopup(false);
                  }}
                  type="button"
                  className="px-4 py-2 mr-2 text-white rounded bg-primary-purple hover:bg-purple-700"
                >
                  <i className="fas fa-plus"></i> Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
};

export default JoinNow;
