import React from "react";
import tick from "../assets/icons/tick-primary.svg";

import price1 from "../assets/images/pricing-1.webp";
import price2 from "../assets/images/pricing-2.webp";
import price3 from "../assets/images/pricing-5.webp";

import "swiper/css";
import "swiper/css/effect-creative";

import FaqPricing from "../components/FaqPricing";
import JoinNow from "../components/JoinNow";
import Footer from "../components/Footer";
import Faq from "../components/Faq";
import { Link } from "react-router-dom";

import {
  LazyLoadImage,
  LazyLoadComponent,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { pricingpage } from "../content/pricing";

const Pricing = () => {
  return (
    <div className="pt-20 pricing">
      <div>
        <h1 className=" text-[44px] font-bold text-center pt-20">
          {pricingpage.headerTitle}
        </h1>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 mt-8 px-8 py-8 bg-[#E7E7E7] rounded-[18px] mx-3 md:mx-0 items-center">
        <div className="">
          <h2 className="text-[32px] font-bold">{pricingpage.cardOne.title}</h2>
          <div className=" space-y-4 pt-5">
            {pricingpage.cardOne.items.length > 0 &&
              pricingpage.cardOne.items.map((item, index) => (
                <div className="flex gap-3 items-center md:text-lg font-medium">
                  <LazyLoadImage src={tick} effect="blur" />
                  <p className="text-purple-text">{item.title}</p>
                </div>
              ))}
          </div>
        </div>
        <div className="hidden lg:block">
          <LazyLoadImage
            className=" hover:scale-100 w-[550px] h-[350px] m-auto"
            src={price1}
            effect="blur"
          />
        </div>
      </div>

      {/* ============ */}

      <div className="grid grid-cols-1 lg:grid-cols-2 mt-[70px] px-8 py-8 bg-[#E7E7E7] rounded-[18px] mx-3 md:mx-0 items-center">
        <div className="">
          <h2 className="text-[32px] font-bold">{pricingpage.cardTwo.title}</h2>
          <div className=" space-y-4 pt-5">
            {pricingpage.cardTwo.items.length > 0 &&
              pricingpage.cardTwo.items.map((item, index) => (
                <div className="flex gap-3 items-center md:text-lg font-medium">
                  <LazyLoadImage src={tick} effect="blur" />
                  <p className="text-purple-text">{item.title}</p>
                </div>
              ))}
          </div>
        </div>
        <div className="hidden lg:block">
          <LazyLoadImage
            className=" hover:scale-100 w-[550px] h-[350px] m-auto"
            src={price2}
            effect="blur"
          />
        </div>
      </div>

      {/* ================ */}

      <div className="grid grid-cols-1 lg:grid-cols-2 mt-[70px] mb-[50px] px-8 py-14 bg-[#E7E7E7] rounded-[18px] mx-3 md:mx-0">
        <div className="">
          <h2 className="text-[32px] font-bold">
            {pricingpage.cardThree.title}
          </h2>
          <div className=" space-y-4 pt-5">
            {pricingpage.cardThree.items.length > 0 &&
              pricingpage.cardThree.items.map((item, index) => (
                <div className="flex gap-3 items-center md:text-lg font-medium">
                  <LazyLoadImage src={tick} effect="blur" />
                  <p className="text-purple-text">{item.title}</p>
                </div>
              ))}
          </div>
        </div>
        <div className="hidden lg:block">
          <LazyLoadImage
            className=" hover:scale-100 w-[450px] h-[320px] m-auto"
            src={price3}
            effect="blur"
          />
        </div>
      </div>

      <div className="flex justify-center">
        <Link to="/register">
          <button className="px-6 py-3 font-bold tracking-wider text-white rounded-full bg-primary-purple hover:bg-primary-purple-dark">
            {pricingpage.btnText}
          </button>
        </Link>
      </div>

      {/* ========== */}
      {/* Faq Section Accordian */}
      <div data-aos="fade-up">
        <LazyLoadComponent>
          <FaqPricing />
        </LazyLoadComponent>
      </div>

      {/* Join now Section */}
      <div data-aos="fade-down">
        <LazyLoadComponent>
          <JoinNow />
        </LazyLoadComponent>
      </div>
      {/* footer */}
      <LazyLoadComponent>
        <Footer />
      </LazyLoadComponent>
    </div>
  );
};

export default Pricing;
