import React from "react";
import support from "../assets/icons/support.svg";
import supportTwo from "../assets/icons/support-two.svg";
import supportOne from "../assets/icons/support-one.svg";
import conversation from "../assets/images/conversation.webp";
import supportBg from "../assets/images/support-bg.webp";
import helpingHand from "../assets/images/helping-hand.webp";
import Footer from "../components/Footer";
import Faq from "../components/Faq";
import JoinNow from "../components/JoinNow";

import {
  LazyLoadImage,
  LazyLoadComponent,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { whyuspage } from "../content/whyus";

const Support = () => {
  return (
    <div className="support-page pt-20">
      {/* header section */}
      <div className="main-section">
        <div className="text text-center py-16">
          <h1 className="text-primary-dark font-bold text-4xl w-10/12 text-center mx-auto ">
            {whyuspage.headerTitle}
          </h1>
          <p className="pt-5">{whyuspage.headerSubtitle}</p>
        </div>

        <div className="grow-business pb-10">
          <div className="md:flex content-center flex-wrap sm:-mx-2 p-3">
            <div
              className="md:flex md:w-1/2 lg:w-1/3 px-2 py-2 card"
              data-aos="fade-up"
            >
              <div className="md:flex-1 p-4  rounded shadow-lg lg:shadow-2xl  border border-white">
                <LazyLoadImage
                  className="py-4 block"
                  alt="support"
                  src={support}
                  effect="blur"
                />
                <h1 className="py-2 text-lg font-bold">
                  {whyuspage.cardOne.title}
                </h1>
                <p>{whyuspage.cardOne.description}</p>
              </div>
            </div>
            <div
              className="md:flex md:w-1/2 lg:w-1/3 px-2 py-2 card"
              data-aos="fade-up"
            >
              <div className="md:flex-1 p-4 rounded shadow-lg lg:shadow-2xl  border border-white">
                <LazyLoadImage
                  className="py-4 block"
                  alt="support"
                  src={supportOne}
                  effect="blur"
                />
                <h1 className="py-2 text-lg font-bold">
                  {whyuspage.cardTwo.title}
                </h1>
                <p>{whyuspage.cardTwo.description}</p>
              </div>
            </div>
            <div
              className="md:flex md:w-1/2 lg:w-1/3 px-2 py-2 card"
              data-aos="fade-down"
            >
              <div className="md:flex-1 p-4 rounded shadow-lg lg:shadow-2xl  border  border-white">
                <LazyLoadImage
                  className="py-4 block"
                  alt="chat"
                  src={supportTwo}
                  effect="blur"
                />
                <h1 className="py-2 text-lg font-bold">
                  {whyuspage.cardThree.title}
                </h1>
                <p>{whyuspage.cardThree.description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* conversation section */}
      <div
        className="py-20 mt-16 items-center text-white bg-light-purple sm:-mx-10 md:-mx-20 px-5 md:px-20"
        style={{
          backgroundImage: `url(${supportBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        data-aos="fade-up"
      >
        <div className="conversation-text">
          <h1 className="font-bold text-3xl md:text-4xl text-center">
            {whyuspage.connect.title}
          </h1>
          <p className="text-base text-center pt-4 lg:w-10/12 m-auto">
            {whyuspage.connect.description}
          </p>
        </div>
        <div className="img py-5">
          <img
            className=" object-cover mx-auto mt-5"
            src={conversation}
            alt="conversation"
            loading="lazy"
          />
        </div>
      </div>

      {/* helping hand */}
      <div className="main-section helping hand pt-10">
        <div className="px-3 sm:px-0 grid grid-cols-1 md:grid-cols-2 items-center lg:gap-10">
          <div className="col " data-aos="fade-up">
            <LazyLoadImage
              className=" w-10/12"
              src={helpingHand}
              alt="helpingHand"
              effect="blur"
            />
          </div>
          <div className="col" data-aos="fade-down">
            <h1
              style={{ lineHeight: "3.5rem" }}
              className="text-primary-dark font-semibold text-2xl md:text-[44px]"
            >
              {whyuspage.missedChats.title}
            </h1>
            <p className="text-primary-dark text-base pt-5">
              {whyuspage.missedChats.description}
            </p>
            <p className="text-primary-dark text-base pt-4">
              {whyuspage.missedChats.description_two}
            </p>
            <p className="text-primary-dark text-base pt-4">
              {whyuspage.missedChats.description_three}
            </p>
          </div>
        </div>
      </div>

      {/* Faq Section Accordian */}
      <div data-aos="fade-up">
        <LazyLoadComponent>
          <Faq />
        </LazyLoadComponent>
      </div>

      {/* Join now Section */}
      <div data-aos="fade-down">
        <LazyLoadComponent>
          <JoinNow />
        </LazyLoadComponent>
      </div>
      {/* footer */}
      <LazyLoadComponent>
        <Footer />
      </LazyLoadComponent>
    </div>
  );
};

export default Support;
