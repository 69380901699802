import React from "react";
import Footer from "../components/Footer";
import loginDots from "../assets/images/login-dots.svg";
import loginDotOne from "../assets/images/login-dotone.svg";
import loginDotTwo from "../assets/images/login-dot-two.svg";

const SignIn = () => {
  return (
    <div className="login pt-40  ">
      {/* our company */}
      <div className="">
        <div className="sign-in sm:w-9/12 lg:w-1/2  flex justify-center mx-auto ">
          <div className="bg-white w-full md:w-11/12 lg:w-4/6 drop-shadow-xl text-center px-5 py-12 relative border-4 border-primary-purple-dark rounded-lg">
            <h1 className="font-bold text-4xl text-primary-dark">
              Sign in Account
            </h1>
            <p className="text-base font-normal pt-3">
              Enter your email and password
            </p>
            <div className="form  pt-5">
              <form className="space-y-4">
                <input
                  className=" block w-full  py-4 border-b-2 pl-3 outline-none focus:border-b-primary-purple duration-200"
                  type="email"
                  name=""
                  id=""
                  placeholder="email@company.com"
                />
                <input
                  className=" block w-full  py-4 border-b-2 pl-3 outline-none focus:border-b-primary-purple duration-200"
                  type="password"
                  name=""
                  id=""
                  placeholder="Password"
                />
                <div className="btn pt-10">
                  <button
                    type="button"
                    className="py-4 px-4 w-1/2 bg-primary-purple hover:bg-primary-purple-dark text-white  transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none rounded-full "
                  >
                    Sign In
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* endd of login */}
        <div className="svgs-images">
          <img
            className="top-40 right-7 md:right-32 lg:right-96 -z-10 absolute"
            src={loginDots}
            alt="decorator"
          />
          <img
            className="bottom-5 sm:bottom-10 left-0 md:left-40 lg:left-96  lg:ml-20 -z-10 absolute"
            src={loginDotOne}
            alt="decorator"
          />
          <img
            className="right-1 md:right-44 lg:bottom-14  lg:mr-80 -z-10 absolute "
            src={loginDotTwo}
            alt="decorator"
          />
        </div>
      </div>

      {/* footer */}
      <Footer />
    </div>
  );
};

export default SignIn;
