import React from "react";

import ReactPlayer from "react-player/lazy";

// Only loads the YouTube player

const VideoSection = () => {
  return (
    <div className="">
      <div className="player-wrapper  ">
        <ReactPlayer
          className="react-player border-2"
          url="https://www.youtube.com/watch?v=uzookzjbpUY&feature=youtu.be"
          width="85%"
          height="85%"
          controls={true}
          volume="0.1"
        />
      </div>
    </div>
  );
};

export default VideoSection;
