import React from "react";
import ourCompany from "../assets/images/our-company.webp";
import aus from "../assets/images/aus.webp";
import pak from "../assets/images/pak.webp";
import account1 from "../assets/images/livchat-two.webp";

import Footer from "../components/Footer";
import Faq from "../components/Faq";
import JoinNow from "../components/JoinNow";
import { Link } from "react-router-dom";
import FaqAbout from "../components/FaqAbout";
import {
  LazyLoadImage,
  LazyLoadComponent,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { aboutpage } from "../content/about";

const AboutUs = () => {
  return (
    <div className="aboutus pt-20">
      {/* our company */}
      <div className="main-section">
        <div className="text text-center py-16" data-aos="fade-up">
          <h1 className="text-primary-dark font-bold text-4xl md:w-3/5 text-center mx-auto  ">
            {aboutpage.headerTitle}
          </h1>
        </div>
        <div className="px-3 sm:px-0 grid grid-cols-1 md:grid-cols-2 pt-10 items-center">
          <div
            className="col py-10 md:pt-10  w-full lg:w-10/12"
            data-aos="fade-up"
          >
            <h1 className="text-primary-dark font-bold text-3xl md:text-5xl">
              {aboutpage.aboutChatmyer.title}
            </h1>
            <p className="text-primary-dark text-lg pt-8">
              {aboutpage.aboutChatmyer.description}
            </p>
            <p className="text-primary-dark text-lg pt-8">
              {aboutpage.aboutChatmyer.description_two}
            </p>
          </div>
          <div className="col " data-aos="fade-down">
            <LazyLoadImage
              className="object-cover ml-auto w-auto lg:max-w-md"
              src={ourCompany}
              alt="Our Company"
              effect="blur"
            />
          </div>
        </div>
      </div>

      {/* Info Section */}
      <div className=" relative about-bar rounded-[20px] text-white text-center bg-primary-purple py-20 mt-20 gap-10 md:gap-0">
        <h1
          className=" font-bold text-lg md:text-4xl w-4/5   mx-auto"
          data-aos="fade-up"
        >
          {aboutpage.business.title}
        </h1>

        <p className="text-white py-8 text-xl" data-aos="fade-right">
          {aboutpage.business.sub_title}
        </p>
        <div className="btn" data-aos="fade-up">
          <Link to="/register">
            <button className=" uppercase px-16 py-4 font-bold bg-white text-primary-purple hover:bg-chatmyer-light-gray rounded-full hover:shadow-2xl duration-300">
              {aboutpage.business.btnText}
            </button>
          </Link>
        </div>

        <svg
          className="absolute top-2 left-9 sm:hidden -z-10"
          width="272"
          height="305"
          viewBox="0 0 272 305"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12" cy="293" r="12" fill="#9079EB" />
          <path
            d="M258.517 119C264.488 122.448 268.846 128.127 270.631 134.787C272.415 141.448 271.481 148.545 268.033 154.517C264.586 160.488 258.907 164.846 252.246 166.631C245.585 168.415 238.488 167.481 232.517 164.033L245.517 141.517L258.517 119Z"
            fill="#5639C7"
          />
          <circle cx="240" cy="8" r="8" fill="#AC9CF8" />
        </svg>
      </div>

      <div className="bg-[#F4F5F7] mt-12 sm:-mx-10 md:-mx-20 px-16 ">
        <div className="flex justify-end mt-[100px] gap-5 md:gap-10 lg:gap-20 flex-wrap py-28 md:flex-nowrap">
          <div>
            <h1 className="text-4xl font-semibold">
              {aboutpage.missionVision.mission.title}
            </h1>
            <p className=" pt-5 text-justify leading-relaxed">
              {aboutpage.missionVision.mission.description}
            </p>
          </div>
          <div>
            <h1 className="text-4xl font-semibold">
              {aboutpage.missionVision.vision.title}
            </h1>
            <p className=" pt-5 text-justify leading-relaxed">
              {aboutpage.missionVision.vision.description}
            </p>
          </div>
        </div>
      </div>

      {/* Our Leadership */}
      {/* <div className="px-3 sm:px-0 mt-8 md:mt-[120px] py-20 items-center bg-[#F4F5F7] md:-mx-20 sm:-mx-10">
        <h1 className=" text-primary-dark text-center font-bold text-xl md:text-3xl leading-tight pb-10  ">
          Our Leadership
        </h1>
        <div className="cards flex justify-between flex-wrap sm:flex-nowrap px-[20px] sm:px-[40px] md:px[80px] lg:px-[135px]  gap-5 md:gap-20">
          <div className="card" data-aos="fade-up">
            <div className="one   bg-chatmyer-light-gray rounded-lg">
              <img
                className="mx-auto object-cover hover:scale-100"
                src={ceo1}
                alt="aboutGallery"
                loading="lazy"
              />
            </div>
            <div className="two text-primary-dark">
              <h1 className="py-5 font-semibold text-base md:text-xl">
                CEO and Chairman of the Board
              </h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Auctor
                neque sed imperdiet nibh lectus feugiat nunc sem.
              </p>
            </div>
          </div>
          <div className="card" data-aos="fade-up">
            <div className="one bg-chatmyer-light-gray rounded-lg">
              <img
                className="mx-auto object-cover hover:scale-100"
                src={ceo2}
                alt="aboutGallery"
                loading="lazy"
              />
            </div>
            <div className="two text-primary-dark">
              <h1 className="py-5 font-semibold text-base md:text-xl">
                CEO and Chairman of the Board
              </h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Auctor
                neque sed imperdiet nibh lectus feugiat nunc sem.
              </p>
            </div>
          </div>
          <div className="card" data-aos="fade-up">
            <div className="onebg-chatmyer-light-gray rounded-lg">
              <img
                className="mx-auto object-cover hover:scale-100"
                src={ceo3}
                alt="aboutGallery"
                loading="lazy"
              />
            </div>
            <div className="two text-primary-dark">
              <h1 className="py-5 font-semibold text-base md:text-xl">
                CEO and Chairman of the Board
              </h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Auctor
                neque sed imperdiet nibh lectus feugiat nunc sem.
              </p>
            </div>
          </div>
        </div>
      </div> */}

      {/* Our Offices */}
      <div className="our-offices py-20 ">
        <h1 className="text-primary-dark font-bold text-2xl md:text-4xl  text-center">
          Our Offices
        </h1>

        <div className="flex justify-center px-4 md:px-0 gap-6 pt-10 text-center flex-wrap md:flex-nowrap">
          <div
            style={{
              backgroundImage: `url(${aus})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              borderRadius: "20px",
            }}
            className="p-4 location relative px-6 flex items-center justify-center my-auto w-[570px] h-[200px] sm:h-[300px] md:h-[300px] cursor-pointer hover:shadow-lg rounded-2xl text-primary-dark"
            data-aos="fade-up"
          >
            <h1 className="py-2 text-3xl absolute bottom-2 right-5 font-semibold text-center text-white z-20">
              Australia
            </h1>
          </div>
          <div
            style={{
              backgroundImage: `url(${pak})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              borderRadius: "20px",
            }}
            className="p-4 location px-6 relative flex items-center justify-center my-auto w-[570px] h-[200px]  sm:h-[300px] md:h-[300px]  cursor-pointer hover:shadow-lg rounded-2xl text-primary-dark"
            data-aos="fade-down"
          >
            <h1 className="py-2 text-3xl absolute bottom-2 right-5 font-semibold text-center text-white z-20">
              Pakistan
            </h1>
          </div>
        </div>
      </div>

      {/* signups */}

      <div className="grid items-center grid-cols-1 gap-5 px-4 sm:px-20 py-10 sm:-mx-10 md:grid-cols-2 bg-light-purple md:-mx-20">
        <div className="w-full col" data-aos="fade-down">
          <h1 className=" text-xl sm:text-2xl font-bold leading-8 text-primary-dark md:text-5xl ">
            {aboutpage.signupSection.title}
          </h1>
        </div>

        <div className="col" data-aos="fade-up">
          <LazyLoadImage
            className="object-cover w-[450px] md:w-[400px] ml-auto "
            src={account1}
            alt="website"
            effect="blur"
          />
        </div>
      </div>

      {/* Faq Section Accordian */}
      <div data-aos="fade-up">
        <LazyLoadComponent>
          <FaqAbout />
        </LazyLoadComponent>
      </div>

      {/* Join now Section */}
      <div data-aos="fade-down">
        <LazyLoadComponent>
          <JoinNow />
        </LazyLoadComponent>
      </div>
      {/* footer */}
      <LazyLoadComponent>
        <Footer />
      </LazyLoadComponent>
    </div>
  );
};

export default AboutUs;
