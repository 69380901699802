import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";
import HomePage from "./pages/HomePage";
import Pricing from "./pages/Pricing";
import { Register } from "./pages/Register";
import SignIn from "./pages/SignIn";
import Support from "./pages/Support";
import TermsConditions from "./pages/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ScrollToTop from "./components/ScrollToTop";
import Mobile from "./pages/Mobile";

function App() {
  return (
    <div className="App scroll-smooth main-div">
      <BrowserRouter>
        <ScrollToTop />
        <Navbar />
        <div className="main-app sm:px-10 md:px-20">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/why-us" element={<Support />} />
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/register" element={<Register />} />
            <Route path="/mobile" element={<Mobile />} />
            <Route path="/terms-conditions" element={<TermsConditions />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
