import React, { useState, useRef, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../assets/images/logo.svg";

const Navbar = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const navRef = useRef(null);

  const navMenuToggler = () => {
    setIsNavOpen((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setIsNavOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <header
      className={` fixed w-full z-20 bg-white left-0 ${
        isNavOpen ? "myheader " : "shadow-md"
      } `}
    >
      {/* Navbar layout */}
      <nav ref={navRef} className={`flex px-8 items-center py-4 m-auto   `}>
        <div className="left w-1/2 md:w-1/4">
          <Link to="/">
            <img className="w-40" src={logo} alt="logo" />
          </Link>
        </div>
        <div className="right w-10/12 ">
          <div
            className={`main-menu ${
              isNavOpen ? "show-menu" : "hidden"
            } md:block`}
          >
            <div className="menus flex justify-end h-full items-center ">
              <div
                className={`${
                  isNavOpen
                    ? "show-menu mobile-menu w-full md:w-auto"
                    : "hidden"
                }`}
              >
                <div className={` flex justify-between px-8 py-4`}>
                  <Link to="/" className={`mobile-logo `}>
                    <img className="w-40" src={logo} alt="" />
                  </Link>

                  {isNavOpen && (
                    // <div className={`close-menu-btn cursor-pointer md:hidden`}>
                    <div className={`cursor-pointer mt-1 md:hidden`}>
                      <svg
                        onClick={() => setIsNavOpen(false)}
                        width="40"
                        height="40"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                          stroke="#000"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.16992 14.83L14.8299 9.17001"
                          stroke="#000"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M14.8299 14.83L9.16992 9.17001"
                          stroke="#000"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  )}
                </div>
              </div>

              <ul className="flex text-[15px] lg:text-base justify-around lg:w-1/2 px-7 gap-2 lg:gap-5 ">
                <li>
                  <NavLink
                    onClick={() => {
                      setIsNavOpen(false);
                    }}
                    to="/about"
                    className="hover:underline hover:underline-offset-8 font-medium decoration-primary-purple decoration-2	"
                  >
                    About
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    onClick={() => {
                      setIsNavOpen(false);
                    }}
                    to="/pricing"
                    className="hover:underline hover:underline-offset-8 font-medium decoration-primary-purple decoration-2	"
                  >
                    Pricing
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    onClick={() => {
                      setIsNavOpen(false);
                    }}
                    to="/why-us"
                    className="hover:underline hover:underline-offset-8 font-medium decoration-primary-purple decoration-2	"
                  >
                    Why Us ?
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    onClick={() => {
                      setIsNavOpen(false);
                    }}
                    to="/contact"
                    className="hover:underline hover:underline-offset-8 font-medium decoration-primary-purple decoration-2"
                  >
                    Contact
                  </NavLink>
                </li>
              </ul>
              <div className="menu-btns flex  md:gap-3 justify-end ">
                <Link
                  onClick={() => {
                    setIsNavOpen(false);
                  }}
                  to="/mobile"
                >
                  <button className="login-btn py-2 px-3 text-sm md:px-4 border font-semibold rounded-full hover:bg-gray-100">
                    SIGN IN
                  </button>
                </Link>
                <Link
                  to="/register"
                  onClick={() => {
                    setIsNavOpen(false);
                  }}
                >
                  <button className="register-btn py-2 px-4 text-sm border font-semibold rounded-full bg-primary-purple hover:bg-primary-purple-dark text-white">
                    SIGN UP FREE
                  </button>
                </Link>
              </div>
            </div>
          </div>
          {/* hamburger menu */}
          <div className="hamburger block md:hidden">
            <div className="menu w-full flex justify-end">
              <div
                className="space-y-2 cursor-pointer"
                onClick={navMenuToggler}
              >
                <div className="w-8 h-1 rounded-xl bg-primary-dark"></div>
                <div className="w-8 h-1 rounded-xl bg-primary-dark"></div>
                <div className="w-8 h-1 rounded-xl bg-primary-dark"></div>
              </div>
            </div>
          </div>
          {/* this will toggle between mobile menu close icon */}
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
