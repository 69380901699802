export const pricingpage = {
  headerTitle: "Features of Chatmyer",
  cardOne: {
    title: "Free",
    items: [
      { title: "Real-Time Chat over WebSocket" },
      { title: "Theme Customization" },
      { title: "Free of the Cost" },
      { title: "Mobile App Accessibility" },
      { title: "Complaint Ticket Support" },
      { title: "Lightest & Fastest Live Chat" },
      { title: "Unlimited Chats" },
      { title: "Instant Local Notifications" },
      { title: "Desktop MAC App" },
      { title: "Image Sharing" },
    ],
  },
  cardTwo: {
    title: "Free",
    items: [
      { title: "High Load Admin Panel" },
      { title: "Message History" },
      { title: "Highly Responsive Interface" },
      { title: "User-friendly Chat UI" },
      { title: "24/7 Customer Support" },
      { title: "Toggle Complaints" },
      { title: "Chat Filtering Options" },
      { title: "Custom Branding on Popup" },
      { title: "Multi-Platform Compatibility" },
      { title: "IOS, Android Admin App" },
      { title: "Message Delivery Status" },
    ],
  },

  cardThree: {
    title: "Coming Soon",
    items: [
      { title: "Sharing Video/Audio and PDF Files (Coming Soon)" },
      { title: "In-Product Messaging (Coming Soon)" },
      { title: "Agent-Based Ticketing System (Coming Soon)" },
      { title: "Create Questions/Answers for Users (Coming Soon)" },
      { title: "AI-Chatbot (Coming Soon)" },
      { title: "Advanced Custom Branding on Popup (Coming Soon)" },
    ],
  },

  btnText: " Get Started Free",
};
