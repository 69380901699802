import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import homeMan from "../assets/images/home-man.webp";
import brandOne from "../assets/images/brand-1.webp";
import brandTwo from "../assets/images/brand-2.webp";
import brandThree from "../assets/images/brand-3.webp";
import brandFour from "../assets/images/brand-4.webp";
import brandFive from "../assets/images/brand-5.webp";
import ios from "../assets/images/ios.webp";
import google from "../assets/images/google.webp";
import liveChat1 from "../assets/images/livechat1.webp";
import liveChat2 from "../assets/images/livechat2.webp";
import liveChat3 from "../assets/images/livechat3.webp";
import coreFeaturesBg from "../assets/images/core-features-bg.webp";
import scanMe from "../assets/images/scanme.webp";
import account1 from "../assets/images/livchat-two.webp";
import account2 from "../assets/images/account2.webp";
import account3 from "../assets/images/account3.webp";
import feature1 from "../assets/images/features.webp";
import feature2 from "../assets/images/feature2.webp";
import feature3 from "../assets/images/feature3.webp";
import feature4 from "../assets/images/feature4.webp";
import chat from "../assets/icons/chat.svg";
import support from "../assets/icons/support.svg";
import chat2 from "../assets/icons/chat2.svg";
import android from "../assets/icons/android.svg";
import image from "../assets/icons/image.svg";
import communication from "../assets/icons/communication.svg";
import ceo from "../assets/images/client.webp";
import farid from "../assets/images/farid.webp";
import jane from "../assets/images/jane.webp";
import abdulwahab from "../assets/images/abdul-wahab.webp";
import olivia from "../assets/images/olivia.webp";
import Footer from "../components/Footer";
import sliderActive from "../assets/icons/slider-active.svg";
import sliderFill from "../assets/icons/slider-fill.svg";
import Faq from "../components/Faq";
import JoinNow from "../components/JoinNow";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-creative";

import { EffectCreative, Autoplay } from "swiper/modules";
import VideoSection from "../components/VideoSection";
import {
  LazyLoadImage,
  LazyLoadComponent,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { homepage } from "../content/homepage";

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide },
  } = rest;
  return (
    <div className=" position-absolute z-10 carousel-arrow ">
      <div className="flex justify-center gap-5 pt-10 slider-imgs">
        <img
          onClick={(e) => {
            console.log(e);
            previous();
          }}
          className=" hover:scale-150"
          data-test-id="prev"
          src={sliderFill}
          alt="slider"
        />
        <img data-test-id="active" src={sliderActive} alt="slider" />
        <img
          onClick={() => next()}
          className=" hover:scale-150"
          data-test-id="next"
          src={sliderFill}
          alt="slider"
        />
      </div>
    </div>
  );
};

const HomePage = () => {
  const [activeFeaturesImage, setActiveFeaturesImage] = useState(feature1);
  const [activeAccountsImage, setActiveAccountsImage] = useState(account1);
  const [activeAccordion, setActiveAccordion] = useState(1);
  const [activeChatbotAccordion, setActiveChatbotAccordion] = useState(1);
  const [animateImageChange, setAnimateImageChange] = useState(false);
  const [animateImageChangeTwo, setAnimateImageChangeTwo] = useState(false);
  const [stopCoreFeatureAnimation, setStopCoreFeatureAnimation] =
    useState(5000);
  const [stopChatBotAnimation, setStopChatBotAnimation] = useState(5000);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const brandsResponsiveness = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 4, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
      slidesToSlide: 4, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
  };

  const [testimonials] = useState([
    {
      title: "Efficient Collaborating",
      description:
        "I was looking for a smart way to handle customer inquiries. Chatmyer helped us to respond on time. We even saw a 30% increase in leads and saved a bunch on service costs. Definitely a best product to use!",
      username: "Moe Chaudhry",
      profession: "CEO",
      company: "Maxmyer",
      image: ceo,
    },

    {
      title: "Mindblowing Service",
      description:
        "Trying out Chatmyer was surprisingly great! It worked like magic and helped us make 54% more sales. Now, it's a big deal for our business strategy.",
      username: "Jane Cooper",
      profession: "Sales Executive ",
      company: "Marcit",
      image: jane,
    },

    {
      title: "Mindblowing Service",
      description:
        "I started using Chatmyer recently, and it's super helpful for talking to customers. The interface is easy to use, and it really helps our technical support team. Perfect for any team that wants smooth communication. ",
      username: "Abdul Wahab",
      profession: "CTO          ",
      company: "AR Instrumed",

      image: abdulwahab,
    },
    {
      title: "Mindblowing Service",
      description:
        "Setting up Chatmyer was easy, and it made customer service just fantastic. With the Chatmyer app, I'm in touch with website visitors, whether at the gym or work. It's like a support team for me 24/7",
      username: "Farid Al-Hakim",
      profession: "Product Manager",
      company: " Pet bazar",

      image: farid,
    },
    {
      title: "Mindblowing Service",
      description:
        "I can't believe how awesome Chatmyer is, and the best part is that It's 100% free. I love that I can chat with my website visitors without any hassle.",
      username: "James Marshall",
      profession: " CO-Founder",
      company: "Hostaroos",

      image: olivia,
    },
  ]);

  useEffect(() => {
    if (activeAccordion === "" || activeAccordion === null) {
      setActiveAccordion(1);
    }
  }, [activeAccordion]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setAnimateImageChange(true); // Trigger the animation

      // Change the active image
      setTimeout(() => {
        if (activeFeaturesImage === feature4) {
          setActiveFeaturesImage(feature1);
        } else if (activeFeaturesImage === feature1) {
          setActiveFeaturesImage(feature2);
        } else if (activeFeaturesImage === feature2) {
          setActiveFeaturesImage(feature3);
        } else if (activeFeaturesImage === feature3) {
          setActiveFeaturesImage(feature4);
        }
      }, 1600);

      // Reset the animation flag after the animation duration
      setTimeout(() => {
        setAnimateImageChange(false);
      }, 1600 + 1500); // 1600 (image change) + 1500 (animation duration) = 3100
    }, stopCoreFeatureAnimation);

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, [activeFeaturesImage]);

  // chatbot animation section

  useEffect(() => {
    const intervalId = setInterval(() => {
      setAnimateImageChangeTwo(true); // Trigger the animation

      // Change the active image
      setTimeout(() => {
        if (activeAccountsImage === account3) {
          setActiveAccountsImage(account1);
        } else if (activeAccountsImage === account1) {
          setActiveAccountsImage(account2);
        } else if (activeAccountsImage === account2) {
          setActiveAccountsImage(account3);
        }
      }, 1250);

      // Reset the animation flag after the animation duration
      setTimeout(() => {
        setAnimateImageChangeTwo(false);
      }, 1250 + 1500); // 1250 (image change) + 1500 (animation duration) = 2750
    }, stopChatBotAnimation);

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, [activeAccountsImage]);

  return (
    <div className="pt-20 md:pt-32 homepage">
      <div className="px-3 sm:px-0 main-section getstarted ">
        <div className="grid grid-cols-1 md:grid-cols-2  items-center">
          <div className="py-10 col md:pt-10 ">
            <h1 className="text-2xl md:text-4xl font-bold text-primary-dark lg:text-[52px] home-header-text ">
              {homepage.header.title}
              <span className="underline decoration-[5px]  md:decoration-[6px] underline-offset-[10px] lg:underline-offset-[14px] decoration-primary-purple">
                Engage
              </span>{" "}
              Customers
            </h1>
            <p className="pt-8 text-lg text-primary-dark">
              {homepage.header.description}
            </p>
            <Link to="/register">
              <button className="px-6 py-3 font-semibold tracking-wider mt-10 text-white rounded-full bg-primary-purple hover:bg-primary-purple-dark">
                {homepage.header.buttonText}
              </button>
            </Link>
          </div>
          <div className="col ml-auto">
            <LazyLoadImage
              className="object-cover hover:scale-105 "
              src={homeMan}
              alt="header"
              effect="blur"
            />
          </div>
        </div>
      </div>
      {/* brands */}
      <div className=" py-28 brands" data-aos="fade">
        <Carousel
          responsive={brandsResponsiveness}
          itemClass="p-3"
          infinite={true}
          // renderButtonGroupOutside={true}
          // customButtonGroup={<ButtonGroup />}

          arrows={false}
          autoPlay={true}
          customTransition="all 1000ms"
          transitionDuration={1000}
          partialVisbile={true}
        >
          <LazyLoadImage
            data-aos="fade-up"
            className="w-28 md:w-auto"
            src={brandOne}
            alt="brand-One"
            effect="blurry"
          />
          <LazyLoadImage
            data-aos="fade-up"
            className="w-28 md:w-auto"
            src={brandTwo}
            alt="brand-two"
            effect="blurry"
          />
          <LazyLoadImage
            data-aos="fade-up"
            className="w-28 md:w-auto"
            src={brandThree}
            alt="brand-three"
            effect="blurry"
          />
          <LazyLoadImage
            data-aos="fade-down"
            className="w-28 md:w-auto"
            src={brandFour}
            alt="brand-four"
            effect="blurry"
          />
          <LazyLoadImage
            data-aos="fade-down"
            className="w-28 md:w-auto"
            src={brandFive}
            alt="brand-five"
            effect="blurry"
          />
        </Carousel>
      </div>
      {/* live-chat-section */}
      <div className="px-3 sm:px-0 grid items-center  grid-cols-1 md:grid-cols-2 md:gap-8">
        <div className="col swiper_container" data-aos="fade-up">
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            slidesPerView={1}
            pagination={false}
            modules={[EffectCreative, Autoplay]}
            className="slider-container"
            thumbs={true}
            spaceBetween={100}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            speed={5000}
            creativeEffect={{
              prev: {
                shadow: true,
                translate: [0, 0, -400],
              },
              next: {
                translate: ["100%", 0, 0],
              },
            }}
          >
            <SwiperSlide>
              <div className="relative">
                <LazyLoadImage
                  className="object-cover livechat-image  hover:scale-100"
                  src={liveChat1}
                  alt="live chat"
                  effect="blur"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="relative">
                <LazyLoadImage
                  className="object-cover livechat-image  hover:scale-100"
                  src={liveChat2}
                  alt="live chat"
                  effect="blur"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="relative">
                <LazyLoadImage
                  className="object-cover livechat-image  hover:scale-100"
                  src={liveChat3}
                  alt="live chat"
                  effect="blur"
                />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="col pt-[80px] md:pt-0">
          <h1 className="text-3xl font-bold	 text-primary-dark md:text-5xl mt-4 md:mt-0">
            {homepage.adminPanelSlider.title} <br />{" "}
            {homepage.adminPanelSlider.subTitle}
          </h1>
          <p className="pt-5 text-base text-primary-dark">
            {homepage.adminPanelSlider.description}
          </p>
          <p className="pt-7 text-base text-primary-dark">
            {homepage.adminPanelSlider.subDescription}
          </p>

          <Link to="/register">
            <button className="px-6 py-3 font-semibold tracking-wider mt-10 text-white rounded-full bg-primary-purple hover:bg-primary-purple-dark">
              {homepage.adminPanelSlider.buttonText}
            </button>
          </Link>
        </div>
      </div>
      {/* core-features */}
      <div className="px-3 sm:px-0 hidden md:grid items-center grid-cols-1 mt-[150px] md:grid-cols-2">
        <div className="col" data-aos="fade-up">
          <h1 className="text-3xl font-bold text-primary-dark md:text-[44px] ">
            {homepage.ourCoreFeatures.title}
          </h1>
          <div className="pt-5 space-y-5 cards">
            <div
              onClick={() => {
                setActiveFeaturesImage(feature1);
                setStopCoreFeatureAnimation(3600000);
              }}
              className={`p-3 duration-200 ${
                activeFeaturesImage === feature1
                  ? "border border-primary-purple"
                  : "border"
              } rounded-lg shadow-lg cursor-pointer card hover:border-primary-purple  hover:shadow-sm `}
            >
              <h2 className="text-xl font-bold text-primary-dark">
                {homepage.ourCoreFeatures.tabOne.title}
              </h2>
              <p className="pt-2 text-primary-dark">
                {homepage.ourCoreFeatures.tabOne.description}
              </p>
            </div>
            <div
              onClick={() => {
                setActiveFeaturesImage(feature2);
                setStopCoreFeatureAnimation(3600000);
              }}
              className={`p-3 duration-200 ${
                activeFeaturesImage === feature2
                  ? "border border-primary-purple"
                  : "border"
              } rounded-lg shadow-lg cursor-pointer card hover:border-primary-purple  hover:shadow-sm`}
            >
              <h2 className="text-xl font-bold text-primary-dark">
                {homepage.ourCoreFeatures.tabTwo.title}
              </h2>
              <p className="pt-2 text-primary-dark">
                {homepage.ourCoreFeatures.tabTwo.description}
              </p>
            </div>
            <div
              onClick={() => {
                setActiveFeaturesImage(feature3);
                setStopCoreFeatureAnimation(3600000);
              }}
              className={`p-3 py-6 duration-200 ${
                activeFeaturesImage === feature3
                  ? "border border-primary-purple"
                  : "border"
              } rounded-lg shadow-lg cursor-pointer card hover:border-primary-purple hover:shadow-sm`}
            >
              <h2 className="text-xl font-bold text-primary-dark">
                {homepage.ourCoreFeatures.tabThree.title}
              </h2>
              <p className="pt-2 text-primary-dark">
                {homepage.ourCoreFeatures.tabThree.description}
              </p>
            </div>
            <div
              onClick={() => {
                setActiveFeaturesImage(feature4);
                setStopCoreFeatureAnimation(3600000);
              }}
              className={`p-3 duration-200 ${
                activeFeaturesImage === feature4
                  ? "border border-primary-purple"
                  : "border"
              } rounded-lg shadow-lg cursor-pointer card hover:border-primary-purple  hover:shadow-sm`}
            >
              <h2 className="text-xl font-bold text-primary-dark">
                {homepage.ourCoreFeatures.tabFour.title}
              </h2>
              <p className="pt-2 text-primary-dark">
                {homepage.ourCoreFeatures.tabFour.description}
              </p>
            </div>
          </div>
          {/* get on app store */}
          <div className="flex flex-col items-center md:items-start md:flex-row my-12 gap-5 ">
            <a href={process.env.REACT_APP_IOS_STORE_URL} target="_blank">
              <LazyLoadImage src={ios} alt="Get on IOS" effect="blur" />
            </a>
            <a href={process.env.REACT_APP_ANDROID_STORE_URL} target="_blank">
              <LazyLoadImage src={google} alt="Get on Android" effect="blur" />
            </a>
          </div>
        </div>
        <div
          className="flex flex-col justify-center relative"
          data-aos="fade-down"
        >
          <div
            className={`image-container ${animateImageChange ? "animate" : ""}`}
          >
            <img
              className="object-cover w-[300px] md:w-[240px] lg:w-[300px] m-auto hover:scale-100"
              src={activeFeaturesImage}
              alt="home laptop"
              loading="lazy"
            />
          </div>

          <img
            className=" absolute -z-10 w-12/12 left-4 bottom-36"
            src={coreFeaturesBg}
            loading="lazy"
            alt=""
          />

          <div className=" w-[1/3] sm:w-[102px] m-auto pt-12">
            <img
              className="object-cover "
              src={scanMe}
              alt="QR code"
              loading="lazy"
            />
            <p className="text-center text-primary-purple font-semibold py-3">
              {homepage.ourCoreFeatures.qrCodeText}
            </p>
          </div>
        </div>
      </div>

      {/* mobile core features */}

      <div className="px-3 sm:px-0 py-10 md:hidden accordion-container ">
        <h1 className="text-3xl py-4 font-bold text-primary-dark md:text-[44px] ">
          {homepage.ourCoreFeatures.title}
        </h1>
        <div
          onClick={() => {
            if (activeAccordion === 1) {
              setActiveAccordion(null);
            } else {
              setActiveAccordion(1);
            }

            // setStopCoreFeatureAnimationMobile(3600000);
          }}
          className={`card relative border py-5 px-3 cursor-pointer z-10 ${
            activeAccordion === 1
              ? "border-primary-purple active-border active-accordion-line border-l-4"
              : " bg-white not-active-border accordion-line border-l-4"
          }`}
        >
          <div className="  flex justify-between   ">
            <h1 className="font-bold ">
              {homepage.ourCoreFeatures.tabOne.title}
            </h1>

            {activeAccordion === 1 ? (
              ""
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.07992 8.91003L10.5999 15.43C11.3699 16.2 12.6299 16.2 13.3999 15.43L19.9199 8.91003"
                  stroke="#292D32"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            )}
          </div>

          <div
            className={`content relative  ${
              activeAccordion === 1 ? "active-accordion" : "accordion "
            }`}
          >
            <p>{homepage.ourCoreFeatures.tabOne.description}</p>

            <img
              className="w-[200px] m-auto pt-8 hover:scale-100"
              src={feature1}
              loading="lazy"
              alt=""
            />

            <img
              className=" absolute -z-10 w-9/12 sm:w-7/12 left-1/2 -translate-x-1/2  -bottom-4"
              src={coreFeaturesBg}
              loading="lazy"
              alt=""
            />
          </div>
        </div>

        {/* ======= */}

        <div
          onClick={() => {
            if (activeAccordion === 2) {
              setActiveAccordion("");
            } else {
              setActiveAccordion(2);
            }

            // setStopCoreFeatureAnimationMobile(3600000);
          }}
          className={`card border py-5 cursor-pointer  px-3 mt-8 ${
            activeAccordion === 2
              ? "border-primary-purple active-accordion-line border-l-4"
              : "accordion-line border-l-4"
          }`}
        >
          <div className="  flex justify-between items-center">
            <h1 className="font-bold">
              {homepage.ourCoreFeatures.tabTwo.title}
            </h1>

            {activeAccordion === 2 ? (
              ""
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.07992 8.91003L10.5999 15.43C11.3699 16.2 12.6299 16.2 13.3999 15.43L19.9199 8.91003"
                  stroke="#292D32"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            )}
          </div>

          <div
            className={`content relative  ${
              activeAccordion === 2 ? "active-accordion" : "accordion "
            }`}
          >
            <p>{homepage.ourCoreFeatures.tabTwo.description}</p>

            <img
              className="w-[200px] m-auto pt-8 hover:scale-100"
              src={feature2}
              loading="lazy"
              alt=""
            />

            <img
              className=" absolute -z-10 w-9/12 sm:w-7/12 left-1/2 -translate-x-1/2  -bottom-4"
              src={coreFeaturesBg}
              loading="lazy"
              alt=""
            />
          </div>
        </div>
        {/* ===== */}

        <div
          onClick={() => {
            if (activeAccordion === 3) {
              setActiveAccordion("");
            } else {
              setActiveAccordion(3);
            }

            // setStopCoreFeatureAnimationMobile(3600000);
          }}
          className={`card border py-5 px-3 mt-8 cursor-pointer  ${
            activeAccordion === 3
              ? "border-primary-purple active-accordion-line border-l-4"
              : "accordion-line border-l-4"
          }`}
        >
          <div className="  flex justify-between items-center">
            <h1 className="font-bold">
              {homepage.ourCoreFeatures.tabThree.title}
            </h1>

            {activeAccordion === 3 ? (
              ""
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.07992 8.91003L10.5999 15.43C11.3699 16.2 12.6299 16.2 13.3999 15.43L19.9199 8.91003"
                  stroke="#292D32"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            )}
          </div>

          <div
            className={`content relative  ${
              activeAccordion === 3 ? "active-accordion" : "accordion"
            }`}
          >
            <p>{homepage.ourCoreFeatures.tabThree.description}</p>

            <img
              className="w-[200px] m-auto pt-8 hover:scale-100"
              src={feature3}
              loading="lazy"
              alt=""
            />

            <img
              className=" absolute -z-10 w-9/12 sm:w-7/12 left-1/2 -translate-x-1/2  -bottom-4"
              src={coreFeaturesBg}
              loading="lazy"
              alt=""
            />
          </div>
        </div>
        {/* ====== */}

        <div
          onClick={() => {
            if (activeAccordion === 4) {
              setActiveAccordion("");
            } else {
              setActiveAccordion(4);
            }
            // setStopCoreFeatureAnimationMobile(3600000);
          }}
          className={`card border py-5 cursor-pointer px-3 mt-8 ${
            activeAccordion === 4
              ? "border-primary-purple active-accordion-line border-l-4"
              : "accordion-line border-l-4"
          }`}
        >
          <div className="  flex justify-between items-center ">
            <h1 className="font-bold">
              {homepage.ourCoreFeatures.tabFour.title}{" "}
            </h1>

            {activeAccordion === 4 ? (
              ""
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.07992 8.91003L10.5999 15.43C11.3699 16.2 12.6299 16.2 13.3999 15.43L19.9199 8.91003"
                  stroke="#292D32"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            )}
          </div>

          <div
            className={`content relative  ${
              activeAccordion === 4
                ? "active-accordion active-accordian"
                : "accordion"
            }`}
          >
            <p>{homepage.ourCoreFeatures.tabFour.description}</p>

            <img
              className="w-[200px] m-auto pt-8 hover:scale-100"
              src={feature4}
              loading="lazy"
              alt=""
            />

            <img
              className=" absolute -z-10 w-9/12 sm:w-7/12 left-1/2 -translate-x-1/2  -bottom-4"
              src={coreFeaturesBg}
              loading="lazy"
              alt=""
            />
          </div>
        </div>

        <div className="flex flex-col sm:flex-row items-center sm:justify-center my-12 gap-5 ">
          <a href={process.env.REACT_APP_IOS_STORE_URL} target="_blank">
            <img src={ios} alt="Get on IOS" loading="lazy" />
          </a>
          <a href={process.env.REACT_APP_ANDROID_STORE_URL} target="_blank">
            <img src={google} alt="Get on Android" loading="lazy" />
          </a>
        </div>
      </div>

      {/* preview section */}
      {/* <div className="grid items-center grid-cols-1 gap-5 px-20 py-20 sm:-mx-10 md:grid-cols-2 bg-light-purple md:-mx-20">
        <div className="col" data-aos="fade-up">
          <img
            className="object-cover "
            src={website}
            alt="website"
            loading="lazy"
          />
        </div>
        <div className="w-full col" data-aos="fade-down">
          <h1 className=" text-xl sm:text-3xl font-bold leading-6 text-primary-dark md:text-5xl ">
            Boost Website Engagement with Instant Live Chat Interaction
          </h1>
          <p className="pt-6">
            Engage with your website visitors in real time with Live Chat.
            Simply embed a small code snippet into any button or event on your
            website. When visitors click, the chat pops up instantly, fostering
            immediate and seamless communication.
          </p>
          <p className="pt-6">
            Enhance your user experience and build connections effortlessly.
          </p>
        </div>
      </div> */}

      {/* create-chatbots */}
      <div className="px-3 sm:px-0 hidden md:grid items-center grid-cols-1 pt-20 md:grid-cols-2 ">
        <div className="col" data-aos="fade-up" data-aos-duration="2000">
          <h1 className="text-3xl font-bold text-primary-dark md:text-4xl">
            {homepage.chatBotSection.title}
          </h1>
          <div className="pt-10 space-y-5 cards">
            <div
              onClick={() => {
                setActiveAccountsImage(account1);
                setStopChatBotAnimation(3600000);
              }}
              className={`px-3 py-4 duration-200 ${
                activeAccountsImage === account1
                  ? "border border-primary-purple"
                  : "border"
              } border-gray-200 rounded-lg shadow-lg cursor-pointer card hover:border-primary-purple hover:shadow-sm`}
            >
              <h2 className="text-lg font-semibold text-primary-dark">
                {homepage.chatBotSection.tabOne.title}
              </h2>
              <p className="pt-2 text-primary-dark">
                {homepage.chatBotSection.tabOne.description}
              </p>
            </div>
            <div
              onClick={() => {
                setActiveAccountsImage(account2);
                setStopChatBotAnimation(3600000);
              }}
              className={`px-3 py-4 duration-200 ${
                activeAccountsImage === account2
                  ? "border border-primary-purple"
                  : "border"
              } border-gray-200 rounded-lg shadow-lg cursor-pointer card hover:border-primary-purple hover:shadow-sm`}
            >
              <h2 className="text-lg font-semibold text-primary-dark">
                {homepage.chatBotSection.tabTwo.title}
              </h2>
              <p className="pt-2 text-primary-dark">
                {homepage.chatBotSection.tabTwo.description}
              </p>
            </div>
            <div
              onClick={() => {
                setActiveAccountsImage(account3);
                setStopChatBotAnimation(3600000);
              }}
              className={`px-3 py-6 duration-200 ${
                activeAccountsImage === account3
                  ? "border border-primary-purple"
                  : "border"
              } border-gray-200 rounded-lg shadow-lg cursor-pointer card hover:border-primary-purple hover:shadow-sm`}
            >
              <h2 className="text-lg font-semibold text-primary-dark">
                {homepage.chatBotSection.tabThree.title}
              </h2>
              <p className="pt-2 text-primary-dark">
                {homepage.chatBotSection.tabThree.description}
              </p>
            </div>
          </div>
        </div>
        <div className="col" data-aos="fade-down">
          <div
            className={`image-container ${
              animateImageChangeTwo ? "animate-2 " : ""
            }`}
          >
            <img
              className={`object-cover w-[350px] m-auto `}
              src={activeAccountsImage}
              alt="live chat"
              loading="lazy"
            />
          </div>
        </div>
      </div>

      {/* mobile chatbots section */}

      <div className="px-3 sm:px-0 py-10 md:hidden accordion-container  ">
        <h1 className="text-2xl sm:w-9/12 py-5 font-bold text-primary-dark">
          {homepage.chatBotSection.title}
        </h1>
        <div
          className={`card relative overflow-hidden border py-5 px-3 z-10 ${
            activeChatbotAccordion === 1
              ? "border-primary-purple active-accordion-line border-l-4"
              : " bg-white accordion-line border-l-4"
          }`}
        >
          <div
            className="  flex justify-between cursor-pointer items-center "
            onClick={() => {
              // setStopChatBotAnimationMobile(3600000);

              if (activeChatbotAccordion === 1) {
                setActiveChatbotAccordion(null);
              } else {
                setActiveChatbotAccordion(1);
              }
            }}
          >
            <h1 className="font-bold ">
              {homepage.chatBotSection.tabOne.title}
            </h1>

            {activeChatbotAccordion === 1 ? (
              ""
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.07992 8.91003L10.5999 15.43C11.3699 16.2 12.6299 16.2 13.3999 15.43L19.9199 8.91003"
                  stroke="#292D32"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            )}
          </div>

          <div
            className={`content ${
              activeChatbotAccordion === 1 ? "active-accordion" : "accordion "
            }`}
          >
            <p>{homepage.chatBotSection.tabOne.description}</p>

            <img
              className="w-[350px] m-auto pt-8 hover:scale-100"
              src={account1}
              loading="lazy"
              alt=""
            />
          </div>
        </div>

        {/* ======= */}

        <div
          onClick={() => {
            // setStopChatBotAnimationMobile(3600000);

            if (activeChatbotAccordion === 2) {
              setActiveChatbotAccordion("");
            } else {
              setActiveChatbotAccordion(2);
            }
          }}
          className={`card border cursor-pointer py-5 px-3 mt-8 ${
            activeChatbotAccordion === 2
              ? "border-primary-purple active-accordion-line border-l-4"
              : "accordion-line border-l-4"
          }`}
        >
          <div className="  flex justify-between  ">
            <h1 className="font-bold">
              {homepage.chatBotSection.tabTwo.title}
            </h1>

            {activeChatbotAccordion === 2 ? (
              ""
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.07992 8.91003L10.5999 15.43C11.3699 16.2 12.6299 16.2 13.3999 15.43L19.9199 8.91003"
                  stroke="#292D32"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            )}
          </div>

          <div
            className={`content relative  ${
              activeChatbotAccordion === 2 ? "active-accordion" : "accordion "
            }`}
          >
            <p>{homepage.chatBotSection.tabTwo.description}</p>

            <img
              className="w-[350px] m-auto pt-8 hover:scale-100"
              src={account2}
              loading="lazy"
              alt=""
            />
          </div>
        </div>
        {/* ===== */}

        <div
          onClick={() => {
            // setStopChatBotAnimationMobile(3600000);

            if (activeAccordion === 3) {
              setActiveChatbotAccordion("");
            } else {
              setActiveChatbotAccordion(3);
            }
          }}
          className={`card border cursor-pointer py-5 px-3 mt-8 ${
            activeChatbotAccordion === 3
              ? "border-primary-purple active-accordion-line border-l-4"
              : "accordion-line border-l-4"
          }`}
        >
          <div className="  flex justify-between items-center ">
            <h1 className="font-bold">
              {homepage.chatBotSection.tabThree.title}
            </h1>

            {activeChatbotAccordion === 3 ? (
              ""
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.07992 8.91003L10.5999 15.43C11.3699 16.2 12.6299 16.2 13.3999 15.43L19.9199 8.91003"
                  stroke="#292D32"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            )}
          </div>

          <div
            className={`content relative  ${
              activeChatbotAccordion === 3 ? "active-accordion" : "accordion "
            }`}
          >
            <p>{homepage.chatBotSection.tabThree.description}</p>

            <img
              className="w-[350px] m-auto pt-8 hover:scale-100"
              src={account3}
              loading="lazy"
              alt=""
            />
          </div>
        </div>
        {/* ====== */}
      </div>

      <LazyLoadComponent>
        <VideoSection />
      </LazyLoadComponent>

      {/* grow business section */}
      <div className="grow-business pt-5">
        <h1 className="px-3 sm:px-0 text-2xl py-8 font-bold text-center text-primary-dark md:text-4xl">
          {homepage.growYourBusinessSection.title}
        </h1>

        <div className="flex-wrap content-center p-3 sm:-mx-2 md:flex">
          <div className="px-2 py-2 md:flex md:w-1/2 lg:w-1/3 card">
            <div
              className="p-4 border rounded shadow-lg md:flex-1  border-grey-dark"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <LazyLoadImage
                effect="blur"
                className="block py-4"
                alt="chat"
                src={chat}
              />
              <h1 className="py-2 text-lg font-bold">
                {homepage.growYourBusinessSection.cardOne.title}
              </h1>
              <p>{homepage.growYourBusinessSection.cardOne.description}</p>
            </div>
          </div>
          <div className="px-2 py-2 md:flex md:w-1/2 lg:w-1/3 card">
            <div
              className="p-4 border rounded shadow-lg md:flex-1  border-grey-dark"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <LazyLoadImage
                className="block py-4"
                alt="communication"
                src={communication}
                effect="blur"
              />
              <h1 className="py-2 text-lg font-bold">
                {homepage.growYourBusinessSection.cardTwo.title}
              </h1>
              <p>{homepage.growYourBusinessSection.cardTwo.description}</p>
            </div>
          </div>
          <div className="px-2 py-2 md:flex md:w-1/2 lg:w-1/3 card">
            <div
              className="p-4 border rounded shadow-lg md:flex-1  border-grey-dark"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <LazyLoadImage
                className="block py-4"
                alt="chat"
                src={image}
                effect="blur"
              />
              <h1 className="py-2 text-lg font-bold">
                {homepage.growYourBusinessSection.cardThree.title}
              </h1>
              <p>{homepage.growYourBusinessSection.cardThree.description}</p>
            </div>
          </div>
          <div className="px-2 py-2 md:flex md:w-1/2 lg:w-1/3 card">
            <div
              className="p-4 border rounded shadow-lg md:flex-1  border-grey-dark"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <LazyLoadImage
                className="block py-4"
                alt="gallery"
                src={chat2}
                effect="blur"
              />
              <h1 className="py-2 text-lg font-bold">
                {homepage.growYourBusinessSection.cardFour.title}
              </h1>
              <p>{homepage.growYourBusinessSection.cardFour.description}</p>
            </div>
          </div>
          <div className="px-2 py-2 md:flex md:w-1/2 lg:w-1/3 card">
            <div
              className="p-4 border rounded shadow-lg md:flex-1 border-grey-dark"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <LazyLoadImage
                className="block py-4"
                alt="support"
                src={support}
                effect="blur"
              />

              <h1 className="py-2 text-lg font-bold">
                {homepage.growYourBusinessSection.cardFive.title}
              </h1>
              <p>{homepage.growYourBusinessSection.cardFive.description}</p>
            </div>
          </div>
          <div className="px-2 py-2 md:flex md:w-1/2 lg:w-1/3 card">
            <div
              className="p-4 border rounded shadow-lg md:flex-1  border-grey-dark"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <LazyLoadImage
                className="block py-4"
                alt="android"
                src={android}
                effect="blur"
              />

              <h1 className="py-2 text-lg font-bold">
                {homepage.growYourBusinessSection.cardSix.title}
              </h1>
              <p>{homepage.growYourBusinessSection.cardSix.description}</p>
            </div>
          </div>
        </div>
      </div>

      {/* upcoming Features */}
      <div className="items-center px-8 md:px-20 pt-16 pb-20 sm:-mx-10 bg-primary-purple md:-mx-20 mt-5 md:mt-[50px] lg:mt-[100px]">
        <h1 className="pb-8 text-xl font-bold leading-tight text-center text-white md:text-3xl ">
          {homepage.awesomeFeaturesSection.title}
        </h1>
        <div className="grid grid-cols-1 gap-4 p-3  md:grid-cols-3">
          <div className="animate-card px-3 p-8 border rounded-lg md:flex bg-primary-purple-dark  border-primary-purple-dark">
            <div className=" text-white m ">
              <h1 className="pb-6 text-xl font-bold md:text-[20px]">
                {homepage.awesomeFeaturesSection.cardOne.title}
              </h1>
              <p className=" text-sm">
                {homepage.awesomeFeaturesSection.cardOne.description}
              </p>
            </div>
          </div>
          <div className="animate-card px-3 p-8 border rounded-lg md:flex bg-primary-purple-dark  border-primary-purple-dark">
            <div className=" text-white  ">
              <h1 className="pb-6 text-xl font-bold md:text-[20px]">
                {homepage.awesomeFeaturesSection.cardTwo.title}
              </h1>
              <p className=" text-sm">
                {homepage.awesomeFeaturesSection.cardTwo.description}
              </p>
            </div>
          </div>
          <div className="animate-card px-3 p-8  border rounded-lg  bg-primary-purple-dark  border-primary-purple-dark">
            <div className=" text-white ">
              <h1 className="pb-6 text-xl font-bold md:text-[20px]">
                {homepage.awesomeFeaturesSection.cardThree.title}
              </h1>
              <p className=" text-sm">
                {homepage.awesomeFeaturesSection.cardThree.description}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* clients Testimonials */}

      <div
        className="x-8 md:px-20 pt-20 pb-20 sm:-mx-10  md:-mx-20  bg-[#F4F5F7]"
        data-aos-duration="1000"
        data-aos="fade-up"
      >
        <div className="text-center txt">
          <h1 className="pb-4 text-3xl font-bold leading-tight primary-dark ">
            Our Clients Speak
          </h1>
          <p>Loved by our customers across all industries</p>
        </div>

        <Carousel
          responsive={responsive}
          itemClass="p-3"
          infinite={true}
          renderButtonGroupOutside={true}
          customButtonGroup={<ButtonGroup />}
          arrows={false}
          autoPlaySpeed={2000}
          autoPlay={true}
          transitionDuration={3000}
        >
          {testimonials.map((testimonial, index) => (
            <div
              className="item text-center card flex flex-col pt-8"
              key={index}
            >
              <div className=" px-2 md:flex bg-white  shadow-xl rounded-lg">
                <div className="p-4 rounded-lg">
                  <p className="pt-4 text-xs md:text-sm text-primary-dark min-h-[140px]">
                    {testimonial.description}
                  </p>
                </div>
              </div>
              <div className="text-center client pt-12">
                <LazyLoadImage
                  className="mx-auto w-[50px] h-[50px] rounded-full object-cover"
                  src={testimonial.image}
                  alt="client"
                  effect="blur"
                />
                <h2 className="font-bold">{testimonial.username}</h2>
                <p className="text-xs text-primary-dark pt-1">
                  {testimonial.company}
                </p>
                <p className="text-xs text-primary-dark pt-1">
                  {testimonial.profession}
                </p>
              </div>
            </div>
          ))}
        </Carousel>
      </div>

      {/* Faq Section Accordian */}

      <div data-aos-duration="1500" data-aos="fade-up">
        <LazyLoadComponent>
          <Faq />
        </LazyLoadComponent>
      </div>

      {/* Join now Section */}
      <div data-aos-duration="1500" data-aos="fade-down">
        <LazyLoadComponent>
          <JoinNow />
        </LazyLoadComponent>
      </div>
      {/* footer */}
      <LazyLoadComponent>
        <Footer />
      </LazyLoadComponent>
    </div>
  );
};

export default HomePage;
