export const whyuspage = {
  headerTitle: "Why Use Chatmyer for Customer Support ",
  headerSubtitle:
    "Because you deserve a free and effortless way to connect with your customers, helping both your team and customers reach their goals faster.  ",

  cardOne: {
    title: "Offer a Convenient Way to Reach You",
    description:
      "Enable your customers to connect with you effortlessly through live chat, avoiding the frustration of waiting in call queues.",
  },
  cardTwo: {
    title: "Unlimited Chats, Absolutely Free!",
    description:
      "With live chat for website, engage with your customers without limits. Enjoy unlimited chats, no questions asked. Now, nothing stands in the way of your customer interactions.",
  },
  cardThree: {
    title: "Support Anytime, Anywhere",
    description:
      "Download the Chatmyer App for Android and iOS. Provide top-notch support from any location, ensuring your customers receive assistance even when you're on the move.",
  },

  //   connect with customers section

  connect: {
    title: "Connect with Your Customers Instantly via Live Chat!",
    description:
      "Experience the magic of real-time chats with Chatmyer! Get quick local notifications and enjoy personalized interactions that satisfy your customer’s needs and mature leads.",
  },

  //   missed chat section

  missedChats: {
    title: "No More Missed Chats! Chatmyer is Your Helping Hand",
    description:
      "It's true, your support team isn't made of robots. They can get tired or overloaded, leading to missed chats. With free live chat for website, ensure every question gets a timely response.",
    description_two:
      "Chatmyer lets your support managers answer customer questions, even on the go. Simply download the Chatmyer Mobile App for Android and iOS. Now, your support team can provide online customer support from anywhere.    ",
    description_three:
      "Choose Chatmyer – where communication is limitless, and support is at your fingertips.",
  },
};
