import React, { useEffect, useRef } from "react";
import contactEmail from "../assets/images/contact-email.svg";
import contactChat from "../assets/images/contact-chat.svg";
import infoBg from "../assets/images/info-bg.png";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import {
  LazyLoadImage,
  LazyLoadComponent,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Contact = () => {
  const handleChatPopup = () => {
    document.querySelector(".chatmyer-open-button").click();
  };

  return (
    <div className="contact pt-20">
      {/* our company */}
      <div className="main-section">
        <div className="text text-center py-20 text-primary-dark">
          <h1 className=" font-bold text-4xl  ">Contact Us</h1>
          <p className="pt-3 font-medium">We'd love to hear from you.</p>
        </div>
        <div className="flex flex-col items-center space-y-10 md:space-y-0 md:flex-row justify-around">
          <div className="one" data-aos="fade-up">
            <div className="flex flex-col items-center">
              <LazyLoadImage src={contactEmail} alt="email" effect="blur" />
              <a href="mailto:tec@tecmyer.com.au">
                <button className="px-10 mt-10 font-bold text-primary-purple py-4 mx-auto rounded-full border hover:bg-primary-purple hover:text-white border-primary-purple transition-all duration-200">
                  Start Email
                </button>
              </a>
            </div>
          </div>
          <div className="one" data-aos="fade-down">
            <div className="flex flex-col items-center">
              <LazyLoadImage src={contactChat} alt="chat" effect="blur" />

              <button
                style={{ width: "162px", boxShadow: "none" }}
                onClick={handleChatPopup}
                className="px-10 mt-10 font-bold text-primary-purple py-4 mx-auto rounded-full border hover:bg-primary-purple hover:text-white border-primary-purple transition-all duration-200 chatmyer-open-button"
              >
                Start Chat
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Info Section */}
      <div
        className="text-white text-center bg-primary-purple py-20 mt-20"
        style={{
          backgroundImage: `url(${infoBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          borderRadius: "20px",
        }}
      >
        <h1
          style={{ lineHeight: "3rem" }}
          className=" font-bold text-lg md:text-4xl w-4/5   mx-auto"
          data-aos="fade-up"
        >
          Your next customer is on your website. <br /> Talk to them.
        </h1>
        <div className="btn" data-aos="fade-up">
          <Link to="/register">
            <button className="px-16 py-4 mt-10 font-bold bg-white text-primary-purple hover:bg-chatmyer-light-gray rounded-full hover:shadow-2xl duration-300">
              Get Started
            </button>
          </Link>
        </div>
      </div>

      {/* footer */}
      <LazyLoadComponent>
        <Footer />
      </LazyLoadComponent>
    </div>
  );
};

export default Contact;
