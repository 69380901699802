import React from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";

const TermsConditions = () => {
  return (
    <div className="terms-page pt-20">
      {/* header section */}
      <div className="terms-section">
        <div className="text py-16">
          <h1 className="text-primary-dark font-bold text-4xl">
            Terms and Conditions
          </h1>
          <p className="pt-5">
            Welcome to Chatmyer! These Terms and Conditions set forth an
            agreement between Chatmyer’s clients ("Clients") and Chatmyer, a
            company that governs access to the Services and the
            https://chatmyer.com/ site (the "Site"), software, data feeds,
            information, tools, features, and functionalities available on the
            Site, and/or all and any customer service solutions offered and
            operated by this platform.
          </p>
        </div>
      </div>
      <div className="acceptance-section">
        <div className="text py-5">
          <h1 className="text-primary-dark font-bold text-4xl">
            Acceptance of the Agreement
          </h1>
          <p className="pt-5">
            By entering, visiting, accessing, and/or using our Site after
            accepting this Agreement, You agree to be bound by this Agreement as
            of the date of such access or use of the Service (the "Effective
            Date"). If you are entering into this Agreement on behalf of a
            company, organization, or other legal entity, You are agreeing to
            this Agreement on the representative of that Entity and representing
            to Chatmyer that You have the authority to bind such Entity and its
            Affiliates to this Agreement. You shall not use or authorize any
            usage of the Services if You do not have such authority or if You do
            not agree with this Agreement.
          </p>
          <p className="pt-5">
            Chatmyer reserves the right to amend these Terms and Conditions at
            any time. In such instances, we will add a new date at the top of
            the document ("Effective Date") and notify You of any changes on our
            website's "Terms and Conditions Update" page. You agree to be bound
            by such modifications or revisions if You continue to use the
            Services and/or Site after any changes.
          </p>
        </div>
      </div>
      <div className="responsibilities-section">
        <div className="text py-5">
          <h1 className="text-primary-dark font-bold text-4xl">
            Responsibilities for User Sign-Up
          </h1>
          <p className="pt-5">
            To access or utilize the Services, you must first create a user
            account by supplying all essential information. If you represent a
            company and want to use the Services for business internal purposes,
            we recommend that you and all other users from your company create
            user accounts by giving your corporate contact information. You
            promise to give truthful, accurate, current, and complete
            information about yourself as prompted by the signup process.
          </p>
          <p className="pt-5">
            If you supply false, inaccurate, outdated, or incomplete
            information, or if Chatmyer has reasonable grounds to suspect that
            such information is wrong, inaccurate, or incomplete, we may cancel
            your user account and deny current or future use of any or all of
            the Services.
          </p>
        </div>
      </div>
      <div className="restriction-section">
        <div className="text py-5">
          <h1 className="text-primary-dark font-bold text-4xl">
            Restrictions on Use
          </h1>
          <p className="pt-5">
            This agreement includes several restrictions on the use of the
            Services. As the user, you are not permitted to transfer or share
            the Services with any third party, or create any service based on
            the Services without prior written permission. User licenses are not
            to be shared or used by anyone other than the individual assigned to
            the license. You are also prohibited from attempting to disassemble,
            reverse engineer, or decompile the Services, except where allowed
            under applicable law.
          </p>
          <p className="pt-5">
            In addition, you must agree to third-party website terms and
            conditions before using any links to those sites, and you cannot
            post links to third-party sites or use their logo or company name
            without their prior written permission. Unauthorized attempts to
            access Chatmyer Services, its systems, or networks are prohibited,
            as are any actions that could damage or harm any server, network,
            computer system, or resource belonging to Chatmyer.
          </p>
          <p className="pt-5">
            Creating a false identity or misrepresenting the identity or origin
            of any communication is not allowed, and you cannot host, display,
            upload, modify, publish, transmit, store, update, or share any
            information belonging to another person or entity without their
            permission or consent. The Services cannot be used for any form of
            competitive or benchmarking purposes, and you are not allowed to
            remove or obscure any proprietary or other notices contained within
            the Services.
          </p>
        </div>
      </div>
      <div className="accounts-section">
        <div className="text py-5">
          <h1 className="text-primary-dark font-bold text-4xl">
            Accounts and Administrators
          </h1>
          <p className="pt-5">
            If you create an account for your organization, you can assign one
            or more administrators who can configure and manage end users in
            your organization's account. If a third party creates and configures
            your organization's account on your behalf, they will likely assume
            an administrator role. It's your responsibility to ensure that you
            have a suitable agreement with such third parties that outlines
            their roles and restrictions as an administrator of your
            organization's account. You're accountable for:
          </p>

          <ul className="list-disc pt-4 space-y-2 list-inside">
            <li>Keeping your organization account password confidential</li>
            <li>
              Selecting capable individuals as administrators to manage your
              organization's account
            </li>
            <li>
              Ensuring that all activities associated with your organization's
              account comply with this Agreement.{" "}
            </li>
          </ul>
          <p className="pt-5">
            Please note that Chatmyer is not liable for administering your
            account or managing the Services for you.
          </p>
        </div>
      </div>
      <div className="payments-section">
        <div className="text py-5">
          <h1 className="text-primary-dark font-bold text-4xl">
            Payment, Downgrades, and Upgrades
          </h1>
          <p className="pt-5">
            IPaying accounts or pricing plans requires a valid credit card,
            debit card, or bank account. However, a credit card number is not
            required for the free plan. The Service is invoiced monthly and
            yearly in advance. If the user decides to subscribe to an annual
            price plan, he or she can do so by paying the entire 12-month charge
            in advance.
          </p>

          <p className="pt-5">
            Notwithstanding the fact that we only charge consumers once a year,
            any overage costs will be levied at the end of the next month. All
            order-related monthly restrictions will be reset at the end of each
            month. If a user upgrades to a higher plan in the middle of the
            year, the user will be charged the difference in price for the rest
            of the year. Likewise, in the middle of an annual paying cycle, the
            customer cannot convert to a monthly plan. As well as, in the midst
            of an annual paying cycle, the customer cannot downgrade to a
            cheaper plan.
          </p>
        </div>
      </div>
      <div className="service-section">
        <div className="text py-5">
          <h1 className="text-primary-dark font-bold text-4xl">
            Services Limitations
          </h1>
          <p className="pt-5">
            You shall use the Services solely as contemplated in these Terms and
            Conditions and shall not, directly or indirectly, license,
            sublicense, sell, resell, lease, transfer, assign, distribute,
            timeshare, or otherwise make the Services available to any third
            party, including through any file-sharing method or any application
            hosting service, except as expressly permitted by these Terms and
            Conditions.
          </p>

          <p className="pt-5">
            Notwithstanding the fact that we only charge consumers once a year,
            any overage costs will be levied at the end of the next month. All
            order-related monthly restrictions will be reset at the end of each
            month. If a user upgrades to a higher plan in the middle of the
            year, the user will be charged the difference in price for the rest
            of the year. Likewise, in the middle of an annual paying cycle, the
            customer cannot convert to a monthly plan. As well as, in the midst
            of an annual paying cycle, the customer cannot downgrade to a
            cheaper plan.
          </p>
        </div>
      </div>
      <div className="changes-section">
        <div className="text py-5">
          <h1 className="text-primary-dark font-bold text-4xl">
            Changes to the Services and Pricing
          </h1>
          <p className="pt-5">
            Chatmyer retains the right, at any time and from time to time, to
            alter or discontinue, temporarily or permanently, the Service (or
            any portion thereof), with or without notice. The Service's pricing
            plans, including but not limited to monthly and yearly subscription
            plan prices, are subject to change upon notice from us. Such notice
            can be given at any time by publishing updates to the Site or the
            Service itself. We shall not be accountable to you or any third
            person if the Service is modified, priced, suspended, or
            discontinued.
          </p>
        </div>
      </div>
      <div className="rights-section">
        <div className="text py-5">
          <h1 className="text-primary-dark font-bold text-4xl">
            Rights to Intellectual Property
          </h1>
          <p className="pt-5">
            Each of us (“Chatmyer” and “Users”)shall retain all rights, titles,
            and interests in and to all of our respective patents, inventions,
            copyrights, trademarks, domain names, trade secrets, know-how, and
            any other intellectual property and/or proprietary rights
            (collectively, Intellectual Property Rights). All rights, title, and
            interest in and to the Service, the Site, and all hardware,
            software, and other components of or used to provide the Service and
            Site, including all related Intellectual Property Rights, will
            remain with and belong exclusively to Chatmyer, subject only to the
            limited rights to access and use expressly stated herein.
          </p>
          <ul className="list-disc pl-5 pt-4 space-y-2 list-outside">
            <li>
              Except as authorized by applicable law, you are not entitled to
              use any of the marks or logos displayed on the Site without the
              prior written consent of the trademark owner.
            </li>
            <li>
              Chatmyer makes no claim to the intellectual property rights in the
              material you contribute to the Service.
            </li>
            <li>
              Without Our specific written consent, you may not replicate,
              reproduce, or reuse any piece of the HTML/CSS or visual design
              elements.
            </li>
            <li>
              No right, title or interest in any Content is transferred to you,
              whether by download or otherwise.
            </li>
            <li>
              Chatmyer retains full copyright and Intellectual Property Rights
              to all Content. You may not use, change, copy, distribute,
              transmit, or create any work from any Content received from the
              Site unless specifically allowed by this Agreement.
            </li>
          </ul>
        </div>
      </div>
      <div className="disclaimer-section">
        <div className="text py-5">
          <h1 className="text-primary-dark font-bold text-4xl">
            Disclaimer of Warranty
          </h1>
          <p className="pt-5">
            YOU FULLY AGREE AND UNDERSTAND THAT YOUR USE OF THE SERVICES IS AT
            YOUR OWN RISK. THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE."
            CHATMYER EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES, EXPRESS OR
            IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
            MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. CHATMYER MAKES
            NO WARRANTY THAT THE SERVICES WILL BE UNINTERRUPTED, ON TIME,
            SECURE, OR FREE OF ERRORS. THE DOWNLOAD OR OBTAINMENT OF ANY
            MATERIAL THROUGH THE USE OF THE SERVICES IS AT YOUR OWN DISCRETION
            AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR
            COMPUTER SYSTEM, MOBILE PHONE, ANY WIRELESS DEVICE, OR DATA
            RESULTING FROM THE USE OF THE SERVICES OR THE DOWNLOAD OF ANY SUCH
            MATERIAL. NO ADVICE OR INFORMATION OBTAINED BY YOU FROM CHATMYER,
            ITS EMPLOYEES, OR REPRESENTATIVES, WHETHER WRITTEN OR ORAL, SHALL
            CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE AGREEMENT.
          </p>
        </div>
      </div>
      <div className="suspended-section">
        <div className="text py-5">
          <h1 className="text-primary-dark font-bold text-4xl">
            Suspended and Terminated
          </h1>
          <p className="pt-5">
            In the event of suspected criminal conduct, lengthy periods of
            inactivity, or requests from law enforcement or other government
            agencies, we may suspend your user account or temporarily block
            access to all or part of any Service. User account suspension or
            deactivation complaints should be directed to
            <Link className="text-primary-purple pl-2">
              support@chatmyer.com
            </Link>
          </p>
          <p className="pt-5">
            Moreover, we retain the right to cancel your user account and refuse
            the Services if we have a reasonable assumption that you have
            violated the Agreement.
          </p>
          <p className="pt-5">
            If Chatmyer violates its responsibilities under these Terms and
            Conditions, you have the right to terminate your user account and
            get a prorated refund of any prepaid costs. Denial of access to all
            our Services, deletion of information in your created user accounts
            such as your email address and password, and deletion of all data in
            your account are all consequences of terminating your user account.
          </p>
          <p className="pt-5">
            If you have any questions, concerns, or complaints regarding this
            agreement, we encourage you to contact us using the details below:
          </p>
          <div className="pt-5 space-y-2">
            <Link className="text-primary-purple ">
              https://chatmyer.com/contact
            </Link>
            <br />
            <Link className="text-primary-purple ">support@chatmyer.com</Link>
          </div>
          <p className="pt-5 font-medium">
            This document was last updated on the Effective Date (27/03/2023).
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsConditions;
