export const faqspage = {
  faq: [
    {
      title: "Is Chatmyer free to use? ",
      content:
        "Yes, Chatmyer is truly 100% free. Our commitment to providing a free service means that you can access and utilize the live chat builder's core features without any cost. This includes basic functionalities that can enhance your website's engagement and customer interaction. We believe in making live chat accessible to businesses of all sizes, and our free offering reflects this dedication.",
    },
    {
      title: "How can I get started with Chatmyer? ",
      content:
        " To get started with Chatmyer, simply sign up for a free account on the Chatmyer. Once you have an account, you can explore the range of premium features. If you need help getting started, Chatmyer an all-in-one live chat for the website offers a range of resources and support options to guide you through the process. ",
    },
    {
      title: "Is live chat software secure and reliable? ",
      content:
        "Yes, Chatmyer is built on a secure and reliable live chat for websites that are designed to protect customer data and ensure maximum uptime. Chatmyer the live chat agent website uses advanced security protocols and is constantly monitored to detect and prevent any potential issues. ",
    },
    {
      title:
        "Can website live chat be added to the existing customer service channel?  ",
      content:
        "Yes, live chat can be a valuable addition to your current customer service channel. It provides immediate, convenient support, with personalized interactions based on customer data. Live chat for websites is scalable, cost-effective, and offers analytical insights for continuous improvement. By integrating a live chat agent for website, you demonstrate a modern, customer-centric approach, enhancing overall service efficiency. ",
    },
    {
      title: "Can I respond to chats using my mobile phone? ",
      content:
        "Absolutely! We understand the importance of flexibility and on-the-go responsiveness. With the Chatmyer App, you can easily answer and manage chats directly from your mobile device. This ensures that you stay connected with your customers, even when you're away from your computer. ",
    },
  ],

  faqAbout: [
    {
      title: "What is Chatmyer? ",
      content:
        "Chatmyer is a live chat tool that connects businesses with customers in real-time through live chat on their websites. It's user-friendly, making it easy for businesses to boost sales, offer support, and build relationships. You can sign up for free to see how live chat for website can benefit your business.",
    },
    {
      title: "Do you offer a mobile app?",
      content:
        " Certainly! Live chat for website provides Chatmyer Mobile App, ensuring that you can stay connected with customers even while on the move. These mobile apps offer flexibility and convenience, allowing you to respond to customer needs in real-time, regardless of your location. ",
    },
    {
      title: "Can I disable the ticketing system in live chat?",
      content:
        "Yes, simply toggle off the complaints option in the chatbox. This allows you to provide a chat option for your website visitors. You can easily enable complaints later, ensuring Chatmyer remains fully user-friendly!",
    },
    {
      title: "Is the chat window customizable? ",
      content:
        "Yes, the chat window is fully customizable to suit your brand. You can personalize the chat theme by adjusting colors, ensuring a cohesive and personalized look that aligns with your website's aesthetics. This flexibility allows you to create a live chat for website that seamlessly integrates with the overall design of your website.",
    },
    {
      title: "Does Chatmyer offer an enterprise solution?",
      content:
        "Yes, Chatmyer offers an enterprise solution tailored for larger businesses. Enterprise clients benefit from personalized onboarding, a dedicated success manager, and priority technical support. Additional advantages include the ability to manage multiple business units, billing, and user permissions from a centralized admin account. This is all supported by a robust, multi-layered ISO-certified security framework.",
    },
  ],

  faqPricing: [
    {
      title: "Do I have to enter my credit card details to sign up?",
      content:
        "No, your credit card details are not required for sign-up. Simply provide your email address, set a password, and share your organization's name to unlock free access to Chatmyer's features.",
    },
    {
      title: "Can I delete my account and data?",
      content:
        "Yes, you have the right to be forgotten. You can easily delete your account and all related data instantly from your account settings.",
    },
    {
      title: "Will I ever be charged to use the Chatmyer?",
      content:
        "Currently, Chatmyer is completely free to use. However, in the future, premium features such as the AI chatbot, Agent-Based Ticketing System, File Sharing, and more may have a nominal charge.",
    },
    {
      title: "How does Chatmyer protect my data?",
      content:
        'We follow the best practices in the industry, maintaining high standards from code to infrastructure. Your data is stored across various AWS servers, with regular backups stored in multiple locations. Only a few engineers have access to client data based on a "Need-to-know basis." Chatmyer prioritizes privacy and has been GDPR-compliant from its inception. For more details on our security measures, feel free to Contact Us. ',
    },
    {
      title: "More questions?",
      content:
        "Our help centre is open 24/7, and our global support team is ready to assist you anytime. Feel free to reach out whenever you need help or have additional questions.",
    },
  ],
};
